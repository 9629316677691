import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { BsThreeDots } from "react-icons/bs";
import CancelInterviewModal from "../mini-components/CancelInterviewModal";

const MenuOptionforChat = ({
  onOpen,
  message_id,
  setIsInterviewScheduleModalVisible,
}) => {
  const [isModalVisible, setIsModalVisible] = useState(false);
  const showModal = () => {
    setIsModalVisible(true);
  };

  const closeModal = () => {
    setIsModalVisible(false);
  };
  return (
    <>
      <div className=" flex justify-end text-xs flex-col ml-auto relative top-2 z-50 ">
        <Popover>
          {({ open }) => {
            return (
              <div className="w-full flex justify-end">
                <PopoverButton className="border-none focus:outline-none">
                  <BsThreeDots className="text-white text-2xl " />
                </PopoverButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <PopoverPanel className="right-2 top-5 origin-top-right w-36  border border-black/30 bg-white  text-sm/6 text-black   focus:outline-none shadow-md absolute">
                    <button
                      onClick={() => {
                        localStorage.setItem("status", "rescheduled");
                        localStorage.setItem("message_id", message_id);
                        setIsInterviewScheduleModalVisible(true);
                      }}
                      className="text-neutral-900 group flex items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap"
                    >
                      Reschedule
                    </button>

                    <button
                      onClick={showModal}
                      className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap "
                    >
                      Cancel
                    </button>
                  </PopoverPanel>
                </Transition>
              </div>
            );
          }}
        </Popover>
      </div>
      <CancelInterviewModal
        visible={isModalVisible}
        close={closeModal}
        message_id={message_id}
      />
    </>
  );
};

export default MenuOptionforChat;
