import React, { useState } from "react";
import { Menu } from "@headlessui/react";
import { BsThreeDots } from "react-icons/bs";
import { useNavigate } from "react-router-dom";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import ConfirmInterviewModal from "../../pages/ConfirmInterviewModal";

const CancelReshduleDropdown = ({
  onOpen,
  direction,
  interview,
  message_id,
  status,
  interviewDate,
  interviewTime,
  interviewType,
}) => {
  const navigate = useNavigate();
  const [isModalVisible, setIsModalVisible] = useState(false);

  const handleConfirmClick = () => {
    setIsModalVisible(true);
  };

  return (
    <div className=" relative">
      {" "}
      <Menu>
        <div>
          <Menu.Button className="inline-flex w-full justify-center ">
            <BsThreeDots className="text-2xl text-[#404041]" />
          </Menu.Button>
        </div>
        <Menu.Items className="absolute top-4 right-1 mt-2 w-56 origin-top-right divide-y divide-neutral-100 rounded-md bg-white shadow-lg ring-1 ring-black/5 focus:outline-none">
          <div className="px-1 py-1">
            {status === 0 && (
              <Menu.Item>
                {({ active }) => (
                  <button
                    className={`${
                      active ? "bg-neutral-700 text-white" : "text-neutral-900"
                    } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                    onClick={handleConfirmClick}
                  >
                    Confirm
                  </button>
                )}
              </Menu.Item>
            )}
            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-neutral-700 text-white" : "text-neutral-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    localStorage.setItem("status", "rescheduled");
                    localStorage.setItem("message_id", message_id);
                    navigate(direction);
                    onOpen();
                  }}
                >
                  Reschedule
                </button>
              )}
            </Menu.Item>

            <Menu.Item>
              {({ active }) => (
                <button
                  className={`${
                    active ? "bg-neutral-700 text-white" : "text-neutral-900"
                  } group flex w-full items-center rounded-md px-2 py-2 text-sm`}
                  onClick={() => {
                    localStorage.setItem("status", "cancel");
                    localStorage.setItem("message_id", message_id);
                    onOpen();
                  }}
                >
                  Cancel
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Menu>
      <ConfirmInterviewModal
        message_id={message_id}
        visible={isModalVisible}
        interviewDate={interviewDate}
        interviewTime={interviewTime}
        interviewType={interviewType}
        onCancel={() => setIsModalVisible(false)}
      />
    </div>
  );
};

export default CancelReshduleDropdown;
