import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Home from "./pages/Home";
import Login from "./pages/Login";
import SignUp from "./pages/SignUp";
import OtpScreen from "./pages/OtpScreen";
import ForgetPassword from "./pages/ForgetPassword";
import NewPassword from "./pages/NewPassword";
import { Toaster } from "sonner";
import Footer from "./components/Footer";
import MainLogoLoader from "./components/loading/MainLogoLoader";
import TermAndCondition from "./pages/TermAndCondition";
import OtpScreenNew from "./pages/OtpScreenNew";
import Hello from "./pages/Hello";
import EmailEnterForgetPassword from "./pages/EmailEnterForgetPassword";
import HomeExperimental from "./pages/HomeExperimental";
import TermandconditionSettings from "./pages/TermandconditionSettings";
import useUnreadMessageCount from "./unread/useUnreadMessageCount";
import { LinkedInCallback } from "react-linkedin-login-oauth2";
import AccessToken from "./components/mini-components/AccessToken";
import UserDetailsSocial from "./pages/UserDetailsSocial";
import AccessTokenTwo from "./components/mini-components/AccessTokenTwo";
import MetaTags from "./Metadescription/MetaTags";
import logo from "./assets/barcodecenter.png";
import TermAndConditionMobile from "./pages/TermAndConditionMobile";
import TermAndConditionMobileSp from "./pages/TermAndConditionMobileSp";
function App() {
  const [showAppModal, setShowAppModal] = useState(false);

  useEffect(() => {
    // Detect if the user is on a mobile device and not on excluded routes
    const isMobile = /Android|iPhone|iPad|iPod/i.test(navigator.userAgent);
    const excludedRoutes = [
      "/set/policy",
      "/policy/view/sp",
      "/policy/view",
      "/policy",
    ];
    const currentPath = window.location.pathname;

    const isExcludedRoute = excludedRoutes.some((route) =>
      currentPath.startsWith(route)
    );

    if (isMobile && !isExcludedRoute) {
      setShowAppModal(true);
    }
  }, []);

  const handleAppOpen = () => {
    const isAndroid = /Android/i.test(navigator.userAgent);
    const isIOS = /iPhone|iPad|iPod/i.test(navigator.userAgent);

    if (isAndroid) {
      window.location.href =
        "intent://bocaenboca.app#Intent;scheme=https;package=com.bocaenboca.app;end;";
    } else if (isIOS) {
      window.location.href = "bocaenboca://";
    }

    setTimeout(() => {
      if (isAndroid) {
        window.location.href =
          "https://play.google.com/store/apps/details?id=com.bocaenboca.app";
      } else if (isIOS) {
        window.location.href =
          "https://apps.apple.com/us/app/boca-en-boca/id6739795551";
      }
    }, 2500);
  };

  const handleWebContinue = () => {
    setShowAppModal(false);
  };

  return (
    <Router>
      <div className="App h-screen">
        <Toaster richColors />
        {showAppModal && (
          <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
            <div className="bg-white rounded-lg p-6 text-center shadow-lg">
              <h2 className="text-lg font-bold mb-4">Open in App?</h2>
              <p className="mb-4">
                Would you like to open this experience in our mobile app or
                continue using the web?
              </p>
              <div className="flex justify-center space-x-4">
                <button
                  className="bg-neutral-800 text-white px-4 py-2 rounded-lg hover:bg-neutral-600"
                  onClick={handleAppOpen}
                >
                  Open App
                </button>
                <button
                  className="bg-neutral-800 text-white px-4 py-2 rounded-lg hover:bg-neutral-600"
                  onClick={handleWebContinue}
                >
                  Continue on Web
                </button>
              </div>
            </div>
          </div>
        )}
        <Routes>
          <Route
            path="/*"
            element={
              <>
                {/* <MetaTags
                  title="Boca En Boca"
                  description="Connect Directly With #Employers To Find A #Job"
                  url="https://bocaenboca.app/"
                  favicon={logo}
                /> */}
                <HomeExperimental />
              </>
            }
          />
          <Route
            path="/login"
            element={
              <>
                <MetaTags
                  title="Login - Boca En Boca"
                  description="Login to connect directly with employers and find jobs easily."
                  url="https://bocaenboca.app/login"
                  favicon={logo}
                />
                <Login />
              </>
            }
          />
          <Route
            path="/9974590"
            element={
              <>
                <MetaTags
                  title="Loader - Boca En Boca"
                  description="Connecting you to the best opportunities."
                  url="https://bocaenboca.app/9974590"
                  favicon={logo}
                />
                <MainLogoLoader />
              </>
            }
          />
          <Route
            path="/signup"
            element={
              <>
                <MetaTags
                  title="Signup - Boca En Boca"
                  description="Sign up to start your journey to find the perfect job!"
                  url="https://bocaenboca.app/signup"
                  favicon={logo}
                />
                <SignUp />
              </>
            }
          />
          <Route
            path="/otp"
            element={
              <>
                <MetaTags
                  title="OTP Verification - Boca En Boca"
                  description="Verify your account with a one-time password."
                  url="https://bocaenboca.app/otp"
                  favicon={logo}
                />
                <OtpScreen />
              </>
            }
          />
          <Route
            path="/verify-otp"
            element={
              <>
                <MetaTags
                  title="Verify OTP - Boca En Boca"
                  description="Enter your OTP to verify your account."
                  url="https://bocaenboca.app/verify-otp"
                  favicon={logo}
                />
                <OtpScreenNew />
              </>
            }
          />
          <Route
            path="/forget-password"
            element={
              <>
                <MetaTags
                  title="Forget Password - Boca En Boca"
                  description="Reset your password to regain access to your account."
                  url="https://bocaenboca.app/forget-password"
                  favicon={logo}
                />
                <ForgetPassword />
              </>
            }
          />
          <Route
            path="/email-fp"
            element={
              <>
                <MetaTags
                  title="Enter Email - Boca En Boca"
                  description="Enter your email to reset your password."
                  url="https://bocaenboca.app/email-fp"
                  favicon={logo}
                />
                <EmailEnterForgetPassword />
              </>
            }
          />
          <Route
            path="/new-password"
            element={
              <>
                <MetaTags
                  title="Set New Password - Boca En Boca"
                  description="Set a new password to secure your account."
                  url="https://bocaenboca.app/new-password"
                  favicon={logo}
                />
                <NewPassword />
              </>
            }
          />
          <Route
            path="/policy/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                  favicon={logo}
                />
                <TermAndCondition />
              </>
            }
          />
          <Route
            path="/policy/view/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                  favicon={logo}
                />
                <TermAndConditionMobile />
              </>
            }
          />
          <Route
            path="/policy/view/sp/:name"
            element={
              <>
                <MetaTags
                  title="Policy - Boca En Boca"
                  description="Learn more about our terms and conditions."
                  url="https://bocaenboca.app/policy/:name"
                  favicon={logo}
                />
                <TermAndConditionMobileSp />
              </>
            }
          />
          <Route
            path="/linkedin/callback"
            element={
              <>
                <MetaTags
                  title="LinkedIn Callback - Boca En Boca"
                  description="Authenticate via LinkedIn to connect with employers."
                  url="https://bocaenboca.app/linkedin/callback"
                  favicon={logo}
                />
                <LinkedInCallback />
              </>
            }
          />
          <Route
            path="/backend/linkedin/login"
            element={
              <>
                <MetaTags
                  title="LinkedIn Login - Boca En Boca"
                  description="Logging you in using LinkedIn."
                  url="https://bocaenboca.app/backend/linkedin/login"
                  favicon={logo}
                />
                <AccessToken />
              </>
            }
          />
          <Route
            path="/backend/facebook/login"
            element={
              <>
                <MetaTags
                  title="Facebook Login - Boca En Boca"
                  description="Logging you in using Facebook."
                  url="https://bocaenboca.app/backend/facebook/login"
                  favicon={logo}
                />
                <AccessTokenTwo />
              </>
            }
          />
          <Route
            path="/userdetails/:firstName/:email/:uniqueCode/:platform/*"
            element={
              <>
                <MetaTags
                  title="User Details - Boca En Boca"
                  description="Provide your details to complete the registration process."
                  url="https://bocaenboca.app/userdetails/:firstName/:email/:uniqueCode/:platform/"
                  favicon={logo}
                />
                <UserDetailsSocial />
              </>
            }
          />
          <Route
            path="/set/policy/:name"
            element={
              <>
                <MetaTags
                  title="Policy Settings - Boca En Boca"
                  description="Manage your policy settings here."
                  url="https://bocaenboca.app/set/policy/:name"
                  favicon={logo}
                />
                <TermandconditionSettings />
              </>
            }
          />
          <Route
            path="/hello"
            element={
              <>
                <MetaTags
                  title="Hello - Boca En Boca"
                  description="Say hello to the best job platform!"
                  url="https://bocaenboca.app/hello"
                  favicon={logo}
                />
                <Hello />
              </>
            }
          />
        </Routes>
      </div>
    </Router>
  );
}

export default App;
