import React from "react";
import ReactPlayer from "react-player";

const ImageGallery = ({ post, openModal }) => {
  if (!post) {
    return null; // Render nothing if post is undefined
  }

  const openModalHandler = (index) => openModal(post, index);

  const media = [
    ...(post.images || []).map((img) => ({ type: "image", file: img.file })),
    ...(post.videos || []).map((vid) => ({ type: "video", file: vid.file })),
  ];

  return (
    <div className="mt-3">
      {media.length > 0 && (
        <div
          className={`grid ${
            media.length === 1
              ? "grid-cols-1"
              : media.length === 2
              ? "grid-cols-2"
              : media.length === 4
              ? "grid-cols-3 grid-rows-[auto_auto]"
              : "grid-cols-2 grid-rows-[auto_auto]"
          } gap-1 mt-3 z-48`}
        >
          {media.slice(0, 4).map((item, index) => (
            <div
              key={index}
              className={`relative ${
                media.length === 4
                  ? index === 0
                    ? "col-span-3 row-span-1"
                    : "col-span-1 row-span-1"
                  : media.length === 3 && index === 2
                  ? "col-span-2 row-span-2"
                  : ""
              }`}
            >
              {item.type === "image" ? (
                <img
                  className={`cursor-pointer object-cover shadow-sm w-full ${
                    index === 0 && media.length === 4
                      ? "h-[333px]"
                      : media.length === 1
                      ? "h-[500px] "
                      : media.length === 2
                      ? "h-[300px]"
                      : media.length === 3
                      ? "h-[333px]"
                      : "h-[300px]"
                  }`}
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${item.file}`}
                  alt={`Post Media ${index + 1}`}
                  onClick={() => openModalHandler(index)}
                />
              ) : (
                <ReactPlayer
                  url={`https://bocaendboca.s3.us-east-2.amazonaws.com/${item.file}`}
                  controls
                  width="100%"
                  height={media.length === 1 ? "500px" : "500px"}
                  className="cursor-pointer object-cover"
                />
              )}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

export default ImageGallery;
