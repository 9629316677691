import React, { useState } from "react";
import { Checkbox } from "@headlessui/react";

import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
const TermAndConditionCheckbox = ({ enabledTandC, setEnabledTandC, texts }) => {
  const navigate = useNavigate();
  return (
    <div className="flex space-x-3 justify-start items-center text-center p-1 w-full ">
      <Checkbox
        checked={enabledTandC}
        onChange={setEnabledTandC}
        className="group size-6 rounded-md bg-black/10 p-1 ring-1 ring-white/15 ring-inset data-[checked]:bg-orange-500 "
      >
        <FaCheck className="hidden size-4 fill-white group-data-[checked]:block " />
      </Checkbox>
      <p className="text-xs lg:text-sm">
        I agree to the{" "}
        <span
          className="text-orange-500 cursor-pointer"
          onClick={() => navigate("/policy/terms_&_conditions")}
        >
          terms & conditions
        </span>{" "}
        &{" "}
        <span
          className="text-orange-500 cursor-pointer"
          onClick={() => navigate("/policy/privacy_policy")}
        >
          privacy policy
        </span>
      </p>
    </div>
  );
};

export default TermAndConditionCheckbox;
