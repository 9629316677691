import React, { useEffect } from "react";
import { useParams } from "react-router-dom";

const AccessToken = () => {
  useEffect(() => {
    // Check if the page has already been refreshed
    if (!localStorage.getItem("refreshed")) {
      // Mark the page as refreshed
      localStorage.setItem("refreshed", "true");
      // Trigger the page reload
      window.location.reload();
    }
  }, []);

  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center h-screen">
      <div className="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default AccessToken;
