import React, { useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import axios from "axios";

const UserDetailsSocial = () => {
  const navigate = useNavigate();
  const { firstName, email, uniqueCode, platform, "*": rest } = useParams();

  // Decode URI components and combine the rest into the last name
  const lastName = decodeURIComponent(rest || ""); // Just directly use `rest`

  // Base URL from environment file
  const BASE_URL = process.env.REACT_APP_API_BASE_URL;

  useEffect(() => {
    const registerUser = async () => {
      const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
      try {
        const response = await axios.post(
          `${BASE_URL}/api/v1/customer/auth/social-registration`,
          {
            first_name: decodeURIComponent(firstName),
            last_name: lastName, // Ensure last name is correctly set
            email: decodeURIComponent(email),
            unique_id: uniqueCode,
            medium: platform,
            type: "job-seeker",
            fcm_token: "",
          },
          {
            headers: {
              "Content-Type": "application/json",
              Accept: "application/json",
              "Time-Zone": userTimeZone,
            },
          }
        );
        console.log("Registration successful:", response.data);

        // Save token to local storage
        const token = response.data.content.token;
        localStorage.setItem("token", token);

        // Remove 'refreshed' item from local storage if it exists
        localStorage.removeItem("refreshed");

        navigate("/"); // Navigate to the homepage or desired route after successful login
      } catch (error) {
        console.error(
          "Error during registration:",
          error.response?.data || error.message
        );
      }
    };

    registerUser();
  }, [firstName, lastName, email, uniqueCode, platform, BASE_URL, navigate]);

  return (
    <div className="flex-col gap-4 w-full flex items-center justify-center h-screen">
      <div className="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default UserDetailsSocial;
