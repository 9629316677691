import React from "react";

const CityLoading = () => {
  return (
    <div class="flex-col gap-4  flex  w-1/2 items-center justify-center h-13">
      <div class="w-10 h-10 border-4 text-neutral-400 text-2xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
    </div>
  );
};

export default CityLoading;
