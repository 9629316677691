import React, { useState, useEffect, useRef, useCallback } from "react";
import { IoIosCheckmarkCircle } from "react-icons/io";
import moment from "moment";
import axios from "axios";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { setActiveNotificationCount } from "../../redux-store/activeNotifications/activeNotificationSlice";
import NotiLoader from "../loading/NotiLoader";
import { FaUserCircle } from "react-icons/fa";
import { Link } from "react-router-dom";

const AllNotifications = () => {
  const [notifications, setNotifications] = useState([]);
  const [loading, setLoading] = useState(true);
  const [offset, setOffset] = useState(1);
  const limit = 10;
  const [showMore, setShowMore] = useState({});
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const toggleShowMore = (id) => {
    setShowMore((prev) => ({ ...prev, [id]: !prev[id] }));
  };
  const observer = useRef();

  const fetchNotifications = async () => {
    try {
      const token = localStorage.getItem("token");
      const userId = localStorage.getItem("userId");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification?limit=${limit}&offset=${offset}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const { data: notificationsData, total: totalCount } =
        response.data.content;

      const filteredNotifications = notificationsData.filter(
        (notification) => notification.sender_user.id !== userId
      );

      setNotifications((prev) => {
        const existingIds = new Set(prev.map((n) => n.id));
        const newNotifications = filteredNotifications.filter(
          (n) => !existingIds.has(n.id)
        );
        return [...prev, ...newNotifications];
      });

      if (notifications.length + filteredNotifications.length >= totalCount) {
        observer.current?.disconnect();
      }

      setLoading(false);
    } catch (error) {
      console.error("Error fetching notifications:", error);
      setLoading(false);
    }
  };

  const fetchNotificationCount = async () => {
    try {
      const token = localStorage.getItem("token");
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/customer/notification/notificationcount`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      const activeCount = response.data.content;
      dispatch(setActiveNotificationCount(activeCount));
    } catch (error) {
      console.error("Error fetching notification count:", error);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [offset]);

  useEffect(() => {
    fetchNotificationCount();
  }, []);

  const lastNotificationRef = useCallback(
    (node) => {
      if (loading) return;
      if (observer.current) observer.current.disconnect();

      observer.current = new IntersectionObserver((entries) => {
        if (entries[0].isIntersecting) {
          setOffset((prev) => prev + 1);
        }
      });

      if (node) observer.current.observe(node);
    },
    [loading]
  );

  const getTimeAgo = (created_at) => {
    const now = moment();
    const createdAt = moment(created_at);

    const diffInUnits = [
      { unit: "months", label: "month" },
      { unit: "days", label: "day" },
      { unit: "hours", label: "hour" },
      { unit: "minutes", label: "minute" },
      { unit: "seconds", label: "second" },
    ];

    for (const { unit, label } of diffInUnits) {
      const diff = now.diff(createdAt, unit);
      if (diff > 0) {
        return `${diff} ${label}${diff > 1 ? "s" : ""} ago`;
      }
    }
    return "Just now";
  };

  const capitalizeFirstLetter = (string) => {
    return string
      ?.replace(/-/g, " ")
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };

  const handleNotificationClick = async (notification) => {
    try {
      const token = localStorage.getItem("token");
      const baseUrl = process.env.REACT_APP_API_BASE_URL;

      if (notification.title.startsWith("New Post Created by")) {
        navigate(`/post/${notification.updates_id}`);
      } else if (notification.title === "Bookmarked your profile") {
        navigate(`/user/${notification.by_user}`);
      } else {
        navigate(`/dm/${notification.channel_id}/${notification.by_user}`);
      }

      await axios.put(
        `${baseUrl}/api/v1/customer/notification/read/${notification.id}`,
        {},
        {
          headers: { Authorization: `Bearer ${token}` },
        }
      );
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  if (loading) {
    return (
      <div>
        {[...Array(9)].map((_, idx) => (
          <NotiLoader key={idx} />
        ))}
      </div>
    );
  }

  const filteredNotifications = notifications.filter(
    (notification) => notification.description !== null
  );
  return (
    <div className="">
      <ul role="list" className="divide-y divide-neutral-100">
        {filteredNotifications.map((notification, index) => (
          <li
            key={notification.id}
            ref={
              notifications.length === index + 1 ? lastNotificationRef : null
            }
            className={`flex w-full justify-between gap-x-3 py-5 hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer z-40 md:px-8 lg:px-8 px-3 ${
              notification.is_active === 0 ? "bg-white" : "bg-neutral-100"
            }`}
            onClick={() => handleNotificationClick(notification)}
          >
            <div className="flex min-w-0 gap-x-3 items-start">
              {/* Profile Image Handling */}
              {notification.sender_user.profile_image &&
              notification.sender_user.profile_image !== "default.png" ? (
                <Link
                  to={`/user/${notification.sender_user.id}`}
                  className="z-50 h-12 w-12 flex-shrink-0"
                >
                  <img
                    className={`w-12 h-12 rounded-full object-cover ${
                      notification.sender_user.type?.toLowerCase() ===
                      "employer"
                        ? "ring-4 ring-[#E7831D]"
                        : "ring-4 ring-neutral-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${notification.sender_user.profile_image}`}
                    alt={`${notification.sender_user.first_name} ${notification.sender_user.last_name}`}
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-neutral-300 flex-shrink-0 ${
                    notification.sender_user.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D] rounded-full"
                      : "ring-4 ring-neutral-700 rounded-full"
                  }`}
                />
              )}

              {/* Notification Details */}
              <div className="min-w-0 flex-auto ml-4">
                <Link to={`/user/${notification.sender_user.id}`}>
                  <p className="text-md font-semibold leading-6 text-neutral-900 cursor-pointer">
                    {notification.sender_user.first_name}{" "}
                    {notification.sender_user.last_name}
                  </p>
                </Link>

                <p
                  className={`text-xs flex items-center ${
                    notification.sender_user.type === "job-seeker"
                      ? "text-black"
                      : "text-[#E7831D]"
                  }`}
                >
                  {capitalizeFirstLetter(notification.sender_user.type)}
                  <IoIosCheckmarkCircle className="ml-1" />
                </p>
                {notification.description && (
                  <div className="mt-2 flex flex-col  text-xs">
                    {notification.description
                      .replace(/\r\n/g, "\n")
                      .split("\n")
                      .slice(0, showMore[notification.id] ? undefined : 1)
                      .map((line, lineIdx) => (
                        <div key={lineIdx}>
                          {line.split(" ").map((word, wordIdx) => (
                            <React.Fragment key={wordIdx}>
                              {word.startsWith("#") ? (
                                <span className="text-[#E7831D] font-bold">
                                  {word}
                                </span>
                              ) : (
                                <span>{word}</span>
                              )}
                              {wordIdx < line.split(" ").length - 1 && " "}
                            </React.Fragment>
                          ))}
                          <br />
                        </div>
                      ))}
                    {notification.description.split("\n").length > 1 && (
                      <button
                        onClick={(event) => {
                          event.stopPropagation();
                          toggleShowMore(notification.id);
                        }}
                        className="text-[#E7831D] mt-1 mr-auto"
                      >
                        {showMore[notification.id] ? "Read Less" : "Read More"}
                      </button>
                    )}
                  </div>
                )}
              </div>
            </div>

            {/* Notification Time */}
            <div className="hidden shrink-0 sm:flex sm:flex-col sm:items-end">
              <p className="mt-1 text-xs leading-5 text-neutral-500">
                {getTimeAgo(notification.created_at)}
              </p>
            </div>
          </li>
        ))}
        {loading && <NotiLoader />}
        <hr />
      </ul>
    </div>
  );
};

export default AllNotifications;
