import React, { useEffect, useState } from "react";
import { Modal } from "antd";
import RadioRole from "./RadioRole"; // Import the RadioRole component
import jobseeker from "../../assets/jobseeker.jpg"; // Ensure correct path to the image
import employer from "../../assets/employer.jpg"; // Ensure correct path to the image
import Loading from "../loading/Loading"; // Import the loading spinner component
import logo from "../../assets/mainlogonew.png";
import StateInput from "../../pages/StateInput"; // Import StateInput component
import CityInput from "../../pages/CityInput"; // Import CityInput component
import MainInputComponent from "../../pages/MainInputComponent"; // Import MainInputComponent for phone input
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";
import axios from "axios";
import CityLoading from "../loading/CityLoading";
const RoleSelectionModal = ({
  visible,
  onClose,
  onSelectRole,
  setSelectedCityId,
  setSelectedStateId,
  setPhoneNumber,
  phoneNumber,
}) => {
  const [selectedRole, setSelectedRole] = useState(null); // No default selection
  const [isLoading, setIsLoading] = useState(false); // State to manage loading state
  // State for phone number
  // State for selected state
  const [selectedCity, setSelectedCity] = useState(""); // State for selected city
  const [loadingTwo, setLoadingTwo] = useState(false);
  const [states, setStates] = useState([]);
  const [selectedState, setSelectedState] = useState("");
  console.log(selectedState, selectedCity, "value");

  const [cities, setCities] = useState([]);
  const selectedLanguage = useSelector((state) => state.language.language);
  const textsTwo = translations[selectedLanguage].signUpPage;
  const texts = translations[selectedLanguage].roleSocial;

  useEffect(() => {
    fetchStates();
  }, []);
  const handleRoleChange = (role) => {
    setSelectedRole(role); // Update the selected role
    setIsLoading(true); // Show the loading spinner
    onSelectRole(role); // Call the onSelectRole callback

    // Simulate loading process (e.g., API request or delay)
    setTimeout(() => {
      setIsLoading(false); // Stop loading after some time
    }, 1500); // Adjust the timeout duration as per the expected loading time
  };

  const handleStateChange = (stateId) => {
    setSelectedStateId(stateId);

    fetchCities(stateId);
  };

  const handleCityChange = (cityId) => {
    setSelectedCityId(cityId);
  };

  const fetchStates = async () => {
    setIsLoading(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/states/233`
      );
      if (response.data.response_code === "default_200") {
        setStates(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching states:", error);
    }
    setIsLoading(false);
  };

  const fetchCities = async (stateId) => {
    setLoadingTwo(true);
    try {
      const response = await axios.get(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/cities/${stateId}`
      );
      if (response.data.response_code === "default_200") {
        setCities(response.data.content);
      }
    } catch (error) {
      console.error("Error fetching cities:", error);
    }
    setLoadingTwo(false);
  };
  return (
    <Modal
      visible={visible}
      onCancel={onClose}
      footer={null}
      centered
      title=""
      // width={900} // Adjust width to make the modal larger
      // bodyStyle={{ padding: "24px" }} // Add padding for better spacing
      closeIcon={null}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3 h-[29rem]">
        {/* Show loading spinner or images based on loading state */}
        {isLoading ? (
          <div className="flex justify-center items-center">
            <Loading /> {/* Display loading spinner */}
          </div>
        ) : (
          <div className="space-y-4">
            <p class="font-inter font-semibold text-2xl leading-7 text-center px-8 py-3 mt-2">
              {texts.text}
            </p>
            <img
              src={logo}
              alt="logo"
              className="w-full h-auto px-16 lg:py-8  md:py-10 p-10 mb-4 "
            />
            {/* Radio buttons below images */}

            {/* <MainInputComponent
              type="number"
              name="phoneNumber"
              value={phoneNumber}
              onChange={(e) => setPhoneNumber(e.target.value)}
              placeholder="Phone Number"
            /> */}
            <div className="flex space-x-3">
              <StateInput
                // value={selectedState}
                onChangestate={handleStateChange}
                option={states}
                // error={errors.state}
                name={textsTwo.selectState}
              />
              {loadingTwo ? (
                <CityLoading />
              ) : (
                <CityInput
                  // value={selectedCity}
                  onChangecity={handleCityChange}
                  option={cities}
                  // error={errors.city}
                  name={textsTwo.selectCity}
                />
              )}
            </div>
            <RadioRole
              selected={selectedRole}
              setSelected={handleRoleChange} // Set role directly when selecting radio button
              plans={["job-seeker", "employer"]} // Pass the role options
            />
          </div>
        )}
      </div>
    </Modal>
  );
};

export default RoleSelectionModal;
