import React, { useState } from "react";
import moment from "moment";
import { Link } from "react-router-dom";
import { FaUserCircle } from "react-icons/fa";
import { IoIosCheckmarkCircle } from "react-icons/io";
import commentIcon from "../../assets/comment.svg";
import { BsThreeDots } from "react-icons/bs";
import CancelReshduleDropdown from "./CancelReshduleDropdown";
import InterviewScheduleModal from "../messagePage/interview/InterviewScheduleModal";

const capitalizeFirstLetter = (string) => {
  if (!string) return "";
  const formattedString = string.replace(/-/g, " ");
  return formattedString
    .split(" ")
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(" ");
};

const formatTime = (time) => moment(time, "HH:mm:ss").format("hh:mm A");

const InterviewListItem = ({ interview, channelid }) => {
  const [isInterviewScheduleModalVisible, setIsInterviewScheduleModalVisible] =
    useState(false);
  const myUserId = localStorage.getItem("userId");

  // Find the user whose ID does not match yours
  const user = interview.channel_users.find(
    (channelUser) => channelUser.user_id !== myUserId
  )?.user;

  const timeValues = interview.interview_time_values
    ? interview.interview_time_values.split(",")
    : null;
  const startTime = timeValues ? timeValues[0] : null;
  const endTime = timeValues ? timeValues[1] : null;

  const openInterviewScheduleModal = () => {
    const interviewDetails = {
      date: interview.interview_date, // Assuming interview_time contains the date
      time: interview.interview_time_values, // Assuming interview_time_values contains the time
      type: interview.interview_type, // Store the interview type
    };
    localStorage.setItem("interviewDetails", JSON.stringify(interviewDetails));
    setIsInterviewScheduleModalVisible(true);
  };

  const closeInterviewScheduleModal = () => {
    localStorage.removeItem("status");
    setIsInterviewScheduleModalVisible(false);
  };

  return (
    <>
      {interview.interview_confirm !== 2 && (
        <li
          className={`p-4 rounded-lg shadow-sm transition-transform scrollbar-hide duration-300 hover:scale-105 ${
            interview.interview_confirm === 2 ? " bg-neutral-100" : "bg-white"
          }`}
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0 ml-5">
              {user.profile_image ? (
                <Link to={`/user/${user.id}`} className="z-40">
                  <img
                    className={`w-10 h-10 rounded-full object-cover ${
                      interview.interview_confirm === 2 ? "neutralscale" : ""
                    } ${
                      user.type?.toLowerCase() === "employer"
                        ? "ring-4 ring-[#E7831D]"
                        : "ring-4 ring-neutral-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${user.profile_image}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-neutral-300 ${
                    user.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D] rounded-full"
                      : "ring-4 ring-neutral-700 rounded-full"
                  }`}
                />
              )}
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-start">
                <div>
                  <Link
                    to={`/user/${user.id}`}
                    className="text-md font-semibold text-neutral-900 cursor-pointer"
                  >
                    {user.first_name} {user.last_name}
                  </Link>
                  <p className="text-xs text-neutral-600">
                    @{user.username} -{" "}
                    <span
                      className={`${
                        user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(user.type)}
                      <IoIosCheckmarkCircle className="inline ml-1" />
                    </span>
                  </p>
                </div>
                <span className="text-xs text-neutral-500"></span>
              </div>

              <div className="mt-3 flex flex-wrap gap-2 justify-between">
                <div className="text-sm text-neutral-600">
                  <span className="font-semibold">
                    {interview.interview_type}
                  </span>{" "}
                  {interview.interview_confirm === 0 && timeValues ? (
                    <>
                      on{" "}
                      {moment(interview.interview_date).format("DD MMM YYYY")}{" "}
                      at {formatTime(startTime) || "Time not available"}
                      {endTime &&
                        ` or ${formatTime(endTime) || "Time not available"}`}
                    </>
                  ) : interview.interview_confirm === 1 ? (
                    // Use interview_time when interview_confirm is 1
                    <>
                      on{" "}
                      {moment(interview.interview_date).format("DD MMM YYYY")}{" "}
                      {interview.interview_time !== null && (
                        <span>
                          {" "}
                          at{" "}
                          {formatTime(interview.interview_time) ||
                            "Time not available"}
                        </span>
                      )}
                    </>
                  ) : (
                    <>
                      on{" "}
                      {moment(interview.interview_date).format("DD MMM YYYY")}{" "}
                      at{" "}
                      {timeValues
                        ? `${formatTime(startTime) || "Time not available"}${
                            endTime
                              ? ` or ${
                                  formatTime(endTime) || "Time not available"
                                }`
                              : ""
                          }`
                        : "Time not available"}
                    </>
                  )}
                  {interview.interview_confirm === 3 && timeValues && (
                    <>
                      {/* {formatTime(startTime)} */}
                      {/* {endTime && ` or ${formatTime(endTime)}`} */}
                      <span className="block text-xs text-neutral-500">
                        {/* on{" "} */}
                        {/* {moment(interview.interview_date).format("DD MMM YYYY")} */}
                      </span>
                    </>
                  )}
                  {interview.interview_confirm === 2 && timeValues && (
                    <>
                      {/* {formatTime(startTime)}
                      {endTime && ` or ${formatTime(endTime)}`}
                      <span className="block text-xs text-neutral-500">
                        on{" "}
                        {moment(interview.interview_date).format("DD MMM YYYY")}
                      </span> */}
                    </>
                  )}
                </div>

                {interview.interview_confirm === 1 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      interviewType={interview.interview_type}
                      interviewDate={interview.interview_date} // Pass the interview date
                      interviewTime={interview.interview_time_values} // Pass the interview time
                      status={interview.interview_confirm}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                      message_id={interview.id}
                    />
                  </div>
                )}
                {interview.interview_confirm === 3 && (
                  <div className="flex ml-auto gap-5">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      interviewType={interview.interview_type}
                      interviewDate={interview.interview_date} // Pass the interview date
                      interviewTime={interview.interview_time_values} // Pass the interview time
                      status={interview.interview_confirm}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                      message_id={interview.id}
                    />
                  </div>
                )}

                {interview.interview_confirm === 0 && (
                  <div className="flex ml-auto gap-4 flex-row ">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      interviewType={interview.interview_type}
                      interviewDate={interview.interview_date} // Pass the interview date
                      interviewTime={interview.interview_time_values} // Pass the interview time
                      status={interview.interview_confirm}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                      message_id={interview.id}
                    />
                  </div>
                )}
                {interview.interview_confirm === 2 && (
                  <Link
                    to={`/dm/${interview.channel_id}/${user.id}`}
                    className="text-xs text-white bg-[#E7831D] rounded-full px-2 py-1 cursor-pointer"
                  >
                    Canceled
                  </Link>
                )}
              </div>
            </div>
          </div>
          {interview.interview_confirm === 0 && (
            <div className="flex w-full bg-neutral-100 rounded-lg  ">
              <Link
                to={`/dm/${interview.channel_id}/${user.id}`}
                className="  text-[#E7831D] font-bold text-sm rounded-full   px-2 py-1 cursor-pointer w-6/12 mx-auto text-center"
              >
                Pending
              </Link>
            </div>
          )}
        </li>
      )}
      {interview.interview_confirm === 2 && (
        <li
          className={`p-4 rounded-lg shadow-sm transition-transform duration-300 hover:scale-105`}
        >
          <div className="flex items-start space-x-4">
            <div className="flex-shrink-0 ml-5">
              {user.profile_image ? (
                <Link to={`/user/${user.id}`} className="z-40">
                  <img
                    className={`w-10 h-10 rounded-full object-cover ${
                      interview.interview_confirm === 2 ? "neutralscale" : ""
                    } ${
                      user.type?.toLowerCase() === "employer"
                        ? "ring-4 ring-[#E7831D]"
                        : "ring-4 ring-neutral-700"
                    }`}
                    src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${user.profile_image}`}
                    alt="Profile"
                  />
                </Link>
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-neutral-300 ${
                    user.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D] rounded-full"
                      : "ring-4 ring-neutral-700 rounded-full"
                  }`}
                />
              )}
            </div>
            <div className="flex-grow">
              <div className="flex justify-between items-start">
                <div>
                  <h3 className="text-md font-semibold text-neutral-900">
                    {user.first_name} {user.last_name}
                  </h3>
                  <p className="text-xs text-neutral-600">
                    @{user.username} -{" "}
                    <span
                      className={`${
                        user.type === "job-seeker"
                          ? "text-black"
                          : "text-[#E7831D]"
                      }`}
                    >
                      {capitalizeFirstLetter(user.type)}
                      <IoIosCheckmarkCircle className="inline ml-1" />
                    </span>
                  </p>
                </div>
                <span className="text-xs text-neutral-500"></span>
              </div>

              <div className="mt-3 flex flex-wrap gap-2 justify-between">
                <div className="text-sm text-neutral-600">
                  <span className="font-semibold">
                    {interview.interview_type}
                  </span>{" "}
                  {interview.interview_confirm === 0 && timeValues ? (
                    <>
                      on{" "}
                      {moment(interview.interview_date).format("DD MMM YYYY")}{" "}
                      at {formatTime(startTime) || "Time not available"}
                      {endTime &&
                        ` or ${formatTime(endTime) || "Time not available"}`}
                    </>
                  ) : (
                    <>
                      on{" "}
                      {moment(interview.interview_date).format("DD MMM YYYY")}{" "}
                      {interview.interview_time !== null && (
                        <span>
                          {" "}
                          at{" "}
                          {formatTime(interview.interview_time) ||
                            "Time not available"}
                        </span>
                      )}
                    </>
                  )}
                  {interview.interview_confirm === 2 && timeValues && (
                    <>
                      {formatTime(startTime)}
                      {endTime && ` or ${formatTime(endTime)}`}
                      <span className="block text-xs text-neutral-500">
                        on{" "}
                        {moment(interview.interview_date).format("DD MMM YYYY")}
                      </span>
                    </>
                  )}
                </div>

                <div></div>

                <div className="flex ml-auto">
                  <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                    <img
                      src={commentIcon}
                      alt="iconforchat"
                      className="h-6 w-6"
                    />
                  </Link>
                </div>

                {interview.interview_confirm === 0 && (
                  <div className="flex ml-auto gap-4 flex-row ">
                    <Link to={`/dm/${interview.channel_id}/${user.id}`}>
                      <img
                        src={commentIcon}
                        alt="iconforchat"
                        className="h-6 w-6"
                      />
                    </Link>
                    <CancelReshduleDropdown
                      onOpen={openInterviewScheduleModal}
                      interviewType={interview.interview_type}
                      interviewDate={interview.interview_date} // Pass the interview date
                      interviewTime={interview.interview_time_values} // Pass the interview time
                      status={interview.interview_confirm}
                      direction={`/dm/${interview.channel_id}/${user.id}`}
                      message_id={interview.id}
                    />
                  </div>
                )}
              </div>
            </div>
          </div>
          {interview.interview_confirm === 2 && (
            <div className="flex w-full bg-neutral-100 rounded-lg  ">
              <Link
                to={`/dm/${interview.channel_id}/${user.id}`}
                className=" font-bold text-sm text-[#E7831D] rounded-full px-2 py-1 cursor-pointer w-6/12 mx-auto text-center"
              >
                Canceled
              </Link>
            </div>
          )}
        </li>
      )}
      <InterviewScheduleModal
        isVisible={isInterviewScheduleModalVisible}
        onClose={closeInterviewScheduleModal}
        channelid={channelid}
      />
    </>
  );
};

export default InterviewListItem;
