import React, { useState, useEffect } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import axios from "axios";
import translations from "../../languages";
import { useSelector } from "react-redux";
import { FaRegCheckCircle } from "react-icons/fa";
import { FaCircleCheck } from "react-icons/fa6";
const CheckoutSuccess = () => {
  const [searchParams] = useSearchParams();
  const setUpIntent = searchParams.get("setup_intent"); // Get setup_intent from query params
  const defaultPrice = searchParams.get("default_price"); // Get default_price from query params
  const selectedLanguage = useSelector((state) => state.language.language);
  const textsExtra = translations[selectedLanguage].subscriptionTexts;
  const [defaultLoading, setDefaultLoading] = useState(false);
  const [receiptData, setReceiptData] = useState(null); // State to store receipt data
  const navigate = useNavigate();
  const handleSubscriptionConfirm = async () => {
    setDefaultLoading(true);

    const selectedPrice = localStorage.getItem("selectedPrice"); // Retrieve the selected price
    const subscriptionId = localStorage.getItem("subscriptionId");
    const baseURL = process.env.REACT_APP_API_BASE_URL || ""; // Use your environment variable for the base URL
    const token = localStorage.getItem("token"); // Get the token from localStorage

    if (!token) {
      alert("Token is missing. Please log in again.");
      setDefaultLoading(false);
      return;
    }

    const subscriptionConfirmURL = `${baseURL}/api/v1/admin/stripe/subscriptionConfirm`;
    const subscriptionStoreURL = `${baseURL}/api/v1/subsription/store`;

    try {
      // First API: Subscription Confirmation
      const confirmResponse = await axios.post(
        subscriptionConfirmURL,
        {
          price_id: defaultPrice, // Use the retrieved defaultPrice
          setup_intent_id: setUpIntent, // Use the retrieved setUpIntent
        },
        {
          headers: {
            Authorization: `Bearer ${token}`, // Authorization header with token
          },
        }
      );

      console.log("Subscription confirmed:", confirmResponse.data);

      // Extract required data from the first API response
      const stripeid = confirmResponse.data.content.id;

      // Second API: Add Subscription
      const storeResponse = await axios.post(
        subscriptionStoreURL,
        {
          subscription_id: subscriptionId, // Default value if not provided
          stripe_id: stripeid,
          stripe_status: "purchased",
          stripe_price: selectedPrice,
          payment_method: "stripe", // Fixed payment method
          platform: "web",
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      navigate("/subscription");
      // fetchReceipt(baseURL, token);
    } catch (error) {
      console.error("Error during subscription process:", error);
      // alert(error?.response?.data?.message || "Something went wrong!");
      setDefaultLoading(false);
    }
  };

  const fetchReceipt = async (baseURL, token) => {
    const receiptApiURL = `${baseURL}/api/v1/admin/stripe/receipts`;

    try {
      const receiptResponse = await axios.get(receiptApiURL, {
        headers: {
          Authorization: `Bearer ${token}`, // Authorization header with token
        },
      });

      setReceiptData(receiptResponse.data); // Save the receipt data in state
      setDefaultLoading(false);
    } catch (error) {
      console.error("Error fetching receipt:", error);
    }
  };

  useEffect(() => {
    handleSubscriptionConfirm();
  }, []);

  return (
    <>
      {defaultLoading ? (
        <div className="flex-col gap-4 w-full flex items-center justify-center h-screen">
          <div className="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
        </div>
      ) : (
        <div className="p-5">
          <div
            className={`mt-4 w-full  shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-5 py-4`}
          >
            <FaCircleCheck className="text-[4rem] mt-10 text-[#404041]" />
            <h2 className={`text-2xl font-extrabold text-[#404041] `}>
              {textsExtra.piad}
            </h2>
            <p className={`text-sm lg:text-lg px-14 text-justify `}>
              {textsExtra.paidTexts}
            </p>
            <div className="flex w-10/12  justify-between ">
              <span className="font-bold">{textsExtra.value}</span>{" "}
              <span>---</span>
            </div>
            <div className="flex w-10/12  justify-between ">
              <span className="font-bold">{textsExtra.Duration}</span>{" "}
              <span>---</span>
            </div>
            <div className="flex w-10/12  justify-between ">
              <span className="font-bold">{textsExtra.Renews}</span>{" "}
              <span>---</span>
            </div>
            <button
              className={`lg:p-3 md:p-3 p-3  bg-[#404041] text-white lg:px-20 md:px-10 px-10 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
              // Pass subscription data
            >
              {textsExtra.cancelSubscription}
            </button>
          </div>

          <div
            className={`mt-4 w-full  shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-5 py-4`}
          >
            <FaRegCheckCircle className="text-[4rem] mt-10 text-[#404041]" />
            <h2 className={`text-2xl font-extrabold text-[#404041] `}>
              {textsExtra.Upgrade}
            </h2>
            <p className={`text-sm lg:text-lg px-14 text-justify `}>
              {textsExtra.UpgradeText}
            </p>

            <button
              className={`lg:p-3 md:p-3 p-3  bg-[#404041] text-white lg:px-20 md:px-10 px-10 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
              // Pass subscription data
            >
              {textsExtra.UpgradeButton}
            </button>
          </div>
        </div>
      )}
    </>
  );
};

export default CheckoutSuccess;
