import { useState, useEffect } from "react";
import moment from "moment";
import { toast } from "sonner";
import { useSelector } from "react-redux";
import MonthDropdown from "./MonthDropdown";
import translations from "../../languages";
import YearDropdown from "./YearDropdown";
import TimeDropdown from "./TimeDropdown";
import TimeOptionTwo from "./TimeOptionTwo";
import InterviewTypeDropdown from "./InterviewTypeDropdown";
import { Space, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import CalendarGrid from "./CalendarGrid";
import axios from "axios";

export default function MonthViewModal({ close, channelid }) {
  const selectedLanguage = useSelector((state) => state.language.language);
  const texts = translations[selectedLanguage].InterviewSchedule;
  const [currentMonth, setCurrentMonth] = useState(moment());
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedTime, setSelectedTime] = useState(null);
  const [selectedTimeTwo, setSelectedTimeTwo] = useState(null);
  const [currentInterviewType, setCurrentInterviewType] = useState(
    "Select Interview Type"
  );
  const [loadingSubmit, setLoadingSubmit] = useState(false);
  const [errors, setErrors] = useState({});
  const [showCancelConfirmation, setShowCancelConfirmation] = useState(false);

  useEffect(() => {
    const status = localStorage.getItem("status");
    if (status === "cancel") {
      setShowCancelConfirmation(true);
    }
  }, []);

  const generateDaysForMonth = (month) => {
    const startOfMonth = month.clone().startOf("month").startOf("week");
    const endOfMonth = month.clone().endOf("month").endOf("week");
    const days = [];
    let day = startOfMonth.clone().subtract(1, "day");

    while (day.isBefore(endOfMonth, "day")) {
      days.push({
        date: day.add(1, "day").clone(),
        isCurrentMonth: day.isSame(month, "month"),
        isToday: day.isSame(moment(), "day"),
      });
    }
    return days;
  };

  const days = generateDaysForMonth(currentMonth);

  const handleDateClick = (day) => {
    if (day.date.isBefore(moment(), "day")) {
      return;
    }
    setSelectedDate(day.date.format("YYYY-MM-DD"));
  };

  const handleSubmit = async () => {
    const newErrors = {};
    const message_id = localStorage.getItem("message_id");

    if (!selectedTime) newErrors.selectedTime = "Please select a time.";
    if (currentInterviewType === "Select Interview Type")
      newErrors.currentInterviewType = "Please select an interview type.";
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoadingSubmit(true);
    const timeValues = selectedTimeTwo
      ? `${selectedTime},${selectedTimeTwo}`
      : selectedTime;
    const token = localStorage.getItem("token");
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    let channelId;
    const pathname = window.location.pathname;
    const dmMatch = pathname.match(/\/dm\/([^\/]+)/);
    if (dmMatch && dmMatch[1]) {
      channelId = dmMatch[1];
    } else {
      channelId = localStorage.getItem("channelId");
    }

    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const status = localStorage.getItem("status");
    const interviewConfirmValue = status === "rescheduled" ? 3 : 0;

    // Set the appropriate payload based on API requirements
    const formData =
      status === "rescheduled"
        ? {
            id: message_id,
            interview_date: selectedDate,
            interview_time_values: timeValues,
            interview_confirm: interviewConfirmValue,
            interview_type: currentInterviewType,
          }
        : {
            message: "interview scheduled",
            channel_id: channelId,
            chat_type: "interview",
            interview_date: selectedDate,
            interview_time_values: timeValues,
            interview_confirm: interviewConfirmValue,
            interview_type: currentInterviewType,
          };

    try {
      // Dynamically select the API endpoint
      const apiEndpoint =
        status === "rescheduled"
          ? `${baseUrl}/api/v1/customer/chat/interview-status-change`
          : `${baseUrl}/api/v1/customer/chat/send-message`;

      const response = await axios.post(apiEndpoint, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Time-Zone": timezone,
        },
      });

      console.log("API Response:", response.data);
      close();
      setErrors({});

      if (status === "rescheduled") {
        localStorage.removeItem("status");
      }
    } catch (error) {
      console.error("Error sending message:", error);
      setErrors({
        submit: "Failed to schedule interview. Please try again.",
      });
    } finally {
      close();
      setLoadingSubmit(false);
    }
  };

  const handleCancelInterview = async () => {
    const token = localStorage.getItem("token");
    const message_id = localStorage.getItem("message_id"); // Assuming interview_id is stored in localStorage
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const interviewDetails =
      JSON.parse(localStorage.getItem("interviewDetails")) || {};
    const formData = new FormData();
    formData.append("id", message_id || ""); // Use the interview ID
    formData.append("interview_confirm", "2"); // Status for cancellation
    // formData.append("interview_time", ""); // Optional, use as needed

    try {
      await axios.post(
        `${baseUrl}/api/v1/customer/chat/interview-status-change`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Time-Zone": timezone,
          },
        }
      );
      close();
      toast("Interview canceled successfully", {
        style: {
          backgroundColor: "#333",
          color: "#fff",
        },
      });

      window.location.reload();
    } catch (error) {
      console.error("Error canceling interview:", error);
    } finally {
      localStorage.removeItem("status");
    }
  };

  const handleCloseModal = () => {
    localStorage.removeItem("status");
    close();
  };

  return (
    <>
      <div className="lg:flex lg:h-full lg:flex-col">
        {showCancelConfirmation ? (
          <div>
            <p className="text-2xl font-bold mt-10 text-center">
              {" "}
              Do you want to cancel this interview?
            </p>
            <div className="flex gap-3 mt-4">
              <button
                className="p-3 w-full bg-white text-[#404041]  border border-[#404041] font-bold rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-l mt-4 flex items-center justify-center"
                onClick={handleCloseModal}
              >
                <span> No, don’t </span>
              </button>
              <button
                className="p-3 w-full bg-[#404041] text-white font-bold rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-l mt-4 flex items-center justify-center"
                onClick={handleCancelInterview}
                disabled={loadingSubmit}
              >
                {loadingSubmit ? (
                  <Space className="mr-3">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 28, color: "#fff" }}
                          spin
                        />
                      }
                      size="medium"
                    />
                  </Space>
                ) : (
                  <span> Yes, Cancel </span>
                )}
              </button>
            </div>
          </div>
        ) : (
          <>
            <header className="flex items-center justify-between py-5">
              <MonthDropdown
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />
              <YearDropdown
                currentMonth={currentMonth}
                setCurrentMonth={setCurrentMonth}
              />
            </header>

            <CalendarGrid
              days={days}
              selectedDate={selectedDate}
              handleDateClick={handleDateClick}
            />
            <InterviewTypeDropdown
              currentInterviewType={currentInterviewType}
              setCurrentInterviewType={setCurrentInterviewType}
              heading={texts.interviewtype}
            />
            {errors.currentInterviewType && (
              <p className="text-red-500 text-sm mt-1">
                {errors.currentInterviewType}
              </p>
            )}
            <TimeDropdown
              selectedTime={selectedTime}
              setSelectedTime={setSelectedTime}
              heading={texts.timeone}
            />
            {errors.selectedTime && (
              <p className="text-red-500 text-sm mt-1">{errors.selectedTime}</p>
            )}

            <TimeOptionTwo
              selectedTime={selectedTimeTwo}
              setSelectedTime={setSelectedTimeTwo}
              heading={texts.timetwo}
            />

            <button
              className="p-3 w-full bg-[#404041] text-white font-bold rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-l mt-4 flex items-center justify-center"
              onClick={handleSubmit}
              disabled={loadingSubmit}
            >
              {loadingSubmit ? (
                <Space className="mr-3">
                  <Spin
                    indicator={
                      <LoadingOutlined
                        style={{ fontSize: 28, color: "#fff" }}
                        spin
                      />
                    }
                    size="medium"
                  />
                </Space>
              ) : (
                <span> {texts.submit}</span>
              )}
            </button>
          </>
        )}
      </div>
    </>
  );
}
