import React from "react";
import { Modal } from "antd";
import { IoClose } from "react-icons/io5";
import {
  useStripe,
  useElements,
  PaymentElement,
} from "@stripe/react-stripe-js";
import { toast } from "sonner";
import { useSelector } from "react-redux";

const PaymentModal = ({
  paymentForm,
  closeModal,
  clientSecret,
  defaultLoading,
  handleSubscriptionConfirm,
  setUpIntent,
}) => {
  const stripe = useStripe();
  const elements = useElements();
  const defaultPrice = useSelector((state) => state.subscription.defaultPrice);

  const handleSubmit = async (event) => {
    event.preventDefault();
    if (elements == null) {
      return;
    }

    const { error: submitError } = await elements.submit();
    if (submitError) {
      toast.error(submitError.message);
      return;
    }

    if (!stripe || !elements) {
      return;
    }

    // Append the setup intent ID to the redirect URL
    const redirectUrl = `${window.location.origin}/checkout/success?setup_intent=${setUpIntent}&default_price=${defaultPrice}`;
    const result = await stripe.confirmSetup({
      elements,
      clientSecret,
      confirmParams: {
        return_url: redirectUrl,
      },
    });

    if (result.error) {
      toast.error(result.error.message);
    } else {
      handleSubscriptionConfirm();
    }
  };

  return (
    <Modal
      open={paymentForm}
      onOk={closeModal}
      onCancel={closeModal}
      style={{ borderRadius: 50 }}
      width={700}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      {defaultLoading ? (
        <div className="flex-col gap-4 w-full flex items-center justify-center h-[50rem]">
          <div className="w-20 h-20 border-8 text-neutral-400 text-4xl animate-spin border-neutral-300 flex items-center justify-center border-t-white rounded-full"></div>
        </div>
      ) : (
        <div className="p-5">
          <PaymentElement />
          <button
            type="submit"
            disabled={!stripe || !elements}
            onClick={handleSubmit}
            className="bg-[#404041] h-14 mt-10 w-full rounded-full mb-2 font-bold text-white hover:bg-neutral-500 hover:scale-95 transition-transform ease-in-out duration-300"
          >
            Pay
          </button>
        </div>
      )}
    </Modal>
  );
};

export default PaymentModal;
