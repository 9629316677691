import React, { useRef } from "react";
import { IoClose } from "react-icons/io5";
import { Modal } from "antd";
import { Toaster, toast } from "sonner";
import { QRCodeSVG } from "qrcode.react";
import { IoCloudDownloadOutline } from "react-icons/io5";
import logo from "../../assets/barcodecenter.png";
const SharePostPopUp = ({ copymodalVisible, setcopyModalVisible, url }) => {
  const qrCodeRef = useRef(); // Reference for the QR code
  console.log(url, "url");

  const handleCopy = () => {
    navigator.clipboard.writeText(url);
    toast(" URL copied to clipboard!", {
      style: {
        backgroundColor: "#333", // neutral background
        color: "#fff", // White text
      },
    });
    setcopyModalVisible(false);
  };

  const handleDownloadQR = () => {
    const svg = qrCodeRef.current.querySelector("svg");
    const canvas = document.createElement("canvas");
    const svgData = new XMLSerializer().serializeToString(svg);

    const img = new Image();
    const svgBlob = new Blob([svgData], {
      type: "image/svg+xml;charset=utf-8",
    });
    const url = URL.createObjectURL(svgBlob);

    img.onload = () => {
      const padding = 40; // Padding around the QR code
      canvas.width = img.width + padding * 2;
      canvas.height = img.height + padding * 2;

      const ctx = canvas.getContext("2d");

      // Fill with white background
      ctx.fillStyle = "#FFFFFF";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Draw the QR code in the center
      ctx.drawImage(img, padding, padding);
      URL.revokeObjectURL(url);

      // Create a link to download the image as PNG
      const pngUrl = canvas.toDataURL("image/png");
      const downloadLink = document.createElement("a");
      downloadLink.href = pngUrl;
      downloadLink.download = "qrcode.png";
      document.body.appendChild(downloadLink);
      downloadLink.click();
      document.body.removeChild(downloadLink);
    };

    img.src = url;
  };

  const qrCodeSize = 280;
  const logoHeight = 40;
  const logoWidth = 40;

  return (
    <>
      <Toaster position="top-right" />
      <Modal
        visible={copymodalVisible}
        footer={null}
        onCancel={() => setcopyModalVisible(false)}
        centered
        closeIcon={<IoClose className="text-neutral-50" />}
        className="custom-modal"
      >
        <div className="flex flex-col space-y-6 items-center text-center lg:p-8 md:p-6 p-4 bg-white rounded-lg ">
          <p className="text-xl font-semibold text-neutral-800">
            Share this post with your friends!
          </p>
          <div
            ref={qrCodeRef}
            className="mb-5 flex flex-col justify-center items-center space-y-4"
          >
            <QRCodeSVG
              value={url}
              size={qrCodeSize}
              className="border rounded-md shadow-lg"
              minVersion={5}
              fgColor="#424242"
              imageSettings={{
                src: logo,
                height: logoHeight,
                width: logoWidth,
                excavate: true,
              }}
            />

            <button
              className="flex w-full justify-center items-center rounded bg-[#424242] py-1 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-neutral-700 focus:shadow-none active:bg-neutral-700 hover:bg-neutral-700 active:shadow-none"
              type="button"
              onClick={handleDownloadQR}
            >
              <IoCloudDownloadOutline className="mr-2 text-2xl" />
              Download QR Code
            </button>
          </div>

          <div className="w-full max-w-sm min-w-[200px]">
            <div className="relative">
              <input
                value={url}
                type="text"
                className="w-full bg-transparent cursor-not-allowed placeholder:text-neutral-400 text-neutral-700 text-sm border border-slate-200 rounded-md pl-3 pr-16 py-2 transition duration-300 ease focus:outline-none focus:border-slate-400 hover:border-slate-300 shadow-sm focus:shadow"
                readOnly
              />
              <button
                className="absolute right-1 top-1 rounded bg-[#424242] py-1 px-2.5 border border-transparent text-center text-sm text-white transition-all shadow-sm hover:shadow focus:bg-neutral-700 focus:shadow-none active:bg-neutral-700 hover:bg-neutral-700 active:shadow-none disabled:pointer-events-none disabled:opacity-50 disabled:shadow-none"
                type="button"
                onClick={handleCopy}
              >
                Copy URL
              </button>
            </div>
          </div>
        </div>
      </Modal>
    </>
  );
};

export default SharePostPopUp;
