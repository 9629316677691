// components/MetaTags.js
import React from "react";
import { Helmet } from "react-helmet";

const MetaTags = ({ title, description, url, favicon }) => {
  return (
    <Helmet>
      <title>{title}</title>
      <meta name="description" content={description} />
      <link rel="icon" href={favicon} />

      {/* Open Graph meta tags for WhatsApp */}
      <meta property="og:title" content={title} />
      <meta property="og:description" content={description} />
      <meta property="og:image" content={favicon} />
      <meta property="og:url" content={url} />
      <meta property="og:type" content="website" />

      {/* Twitter card meta tags */}
      <meta name="twitter:title" content={title} />
      <meta name="twitter:description" content={description} />
      <meta name="twitter:image" content={favicon} />
      <meta name="twitter:card" content="summary_large_image" />
    </Helmet>
  );
};

export default MetaTags;
