import React, { useState, useEffect } from "react";
import moment from "moment";
import {
  Menu,
  MenuButton,
  MenuItem,
  MenuItems,
  Transition,
} from "@headlessui/react";
import "./modal.css";
import { IoIosCheckmarkCircle } from "react-icons/io";
import { useDispatch, useSelector } from "react-redux";
import { setPostDetails } from "../../redux-store/postEdit/postSlice";

import { setSearchText } from "../../redux-store/postSearch/searchSlice";

import { IoIosBookmark } from "react-icons/io";
import { BsThreeDots } from "react-icons/bs";
import PostModal from "./PostModal";
import { Modal } from "antd";
import { IoBookmarkSharp } from "react-icons/io5";
import { MdCloseFullscreen } from "react-icons/md";
import { Spin } from "antd";
import Loading from "../loading/Loading";
import { Link, useLocation, useNavigate } from "react-router-dom";
import avatar from "../../assets/lady.jpg";
import { FaRegBookmark } from "react-icons/fa";
const BASE_URL = "https://bocaenboca.goodcoderz.com/";
import { GoDotFill } from "react-icons/go";
import commentIcon from "../../assets/comment.svg";
import bookmark from "../../assets/Bookmarks.svg";
import share from "../../assets/share.svg";
import { LuShare } from "react-icons/lu";
import commentWhite from "../../assets/commentwhite.svg";
import bookMarkwhite from "../../assets/bookmarkwhite.svg";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import { Carousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import User from "./User";
import { IoExpand, IoArrowBack, IoArrowForward } from "react-icons/io5";
import { FaUserCircle } from "react-icons/fa";
import { IoClose } from "react-icons/io5";
import MenuOptionscopy from "./MenuOptionscopy";
import translations from "../../languages";
import EditPostModalComponent from "../mini-components/EditPostModalComponent";
import VideoGallery from "../mini-components/VideoGallery";
import ImageGallery from "../mini-components/ImageGallery";
import PostDescription from "../mini-components/PostDescription";
import MediaModal from "../mini-components/MediaModal";
import SharePostPopUp from "../share/SharePostPopUp";
import { LoadingOutlined } from "@ant-design/icons";
import Repost from "./Repost";
const PostForMyProfile = ({
  postdatas,
  handleBookmark,
  handleReportPost,
  createChatChannel,
  channelLoading,
  handleDeletePost,
  loading,
  bookmarkedPosts,
  setFetching,
  fetchPosts,
  setPostLimit,
  fetchData,
  setLoadingpost,
  handleRepost,
}) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();
  const [bookMarkFetching, setBookMarkFetching] = useState({});
  const [error, setError] = useState(null);
  const [displayedPosts, setDisplayedPosts] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalImage, setModalImage] = useState("");
  const [modalData, setModalData] = useState(null);
  const [userExists, setUserExists] = useState(false);
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const [modalIndex, setModalIndex] = useState(null);
  const selectedLanguage = useSelector((state) => state.language.language);
  const [iseditModalVisible, setIseditModalVisible] = useState(false);
  const [copymodalVisible, setcopyModalVisible] = useState(false);
  const [url, setUrl] = useState("");
  const postDetails = useSelector((state) => state.post.postDetails);
  const texts = translations[selectedLanguage].types;
  const options = translations[selectedLanguage].actions;

  const handleTagClick = (tag) => {
    dispatch(setSearchText(tag));
  };

  useEffect(() => {
    if (postDetails?.id) {
      setIseditModalVisible(true);
      window.scrollTo({
        top: 0,
        behavior: "smooth",
      });
    }
  }, [postDetails]);

  useEffect(() => {
    const token = localStorage.getItem("token");
    if (token) {
      setUserExists(true);
    }
  }, []);

  const SvgIcon = ({ src, className, ...props }) => (
    <img src={src} className={className} alt="" {...props} />
  );

  useEffect(() => {
    setDisplayedPosts(
      postdatas.map((post) => ({ ...post, bookMarked: false }))
    );
  }, [postdatas]);

  const handleBookmarkClick = (userId) => {
    handleBookmark(userId);
  };
  const openModal = (post, index) => {
    setModalData(post);
    setModalIndex(index); // Store the index
    setIsModalOpen(true);
  };

  // Update the handleBookMark function inside the Modal
  const handleModalBookmark = async () => {
    if (!modalData || modalIndex === null) return;
    await handleBookMark(modalData.id, modalIndex);
  };
  const closeModal = () => {
    setIsModalOpen(false);
    setModalData(null);
  };
  const handleBookMarkdelete = async (postId, index) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    setBookMarkFetching((prevState) => ({ ...prevState, [postId]: true }));

    try {
      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/bookmark/delete/${postId}`,
        {
          method: "DELETE",
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("token")}`,
          },
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setDisplayedPosts((prevPosts) => {
          const updatedPosts = [...prevPosts];
          updatedPosts[index].bookMarked = false;
          updatedPosts[index].bookmark_status = [];
          return updatedPosts;
        });
      } else {
        console.log("Something went wrong while removing bookmark");
      }
    } catch (error) {
      console.error("Error removing bookmark:", error);
    } finally {
      setBookMarkFetching((prevState) => ({ ...prevState, [postId]: false }));
    }
  };
  const handleBookMark = async (postId, index) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    setBookMarkFetching((prevState) => ({ ...prevState, [postId]: true }));
    setError(null);
    try {
      let formData = new FormData();
      formData.append("post_id", postId);

      const response = await fetch(
        `${process.env.REACT_APP_API_BASE_URL}/api/v1/user/bookmark/store`,
        {
          method: "POST",
          headers: {
            Authorization: `Bearer  ${localStorage.getItem("token")}`,
          },
          body: formData,
        }
      );
      if (response.status >= 200 && response.status < 300) {
        setDisplayedPosts((prevPosts) => {
          const updatedPosts = [...prevPosts];
          updatedPosts[index].bookMarked = !updatedPosts[index].bookMarked;
          return updatedPosts;
        });
      } else {
        const res = await response.json();
        setError(res.message);
        return;
      }
    } finally {
      setBookMarkFetching((prevState) => ({ ...prevState, [postId]: false }));
    }
  };

  const handleEditPost = (post) => {
    dispatch(
      setPostDetails({
        id: post.id,
        description: post.description,
        photos: post.images,
        videos: post.videos,
      })
    );

    window.scrollTo({
      top: 0,
      behavior: "smooth",
    });
    setIseditModalVisible(true);
  };
  const capitalizeFirstLetter = (string) => {
    if (!string) return "";

    const formattedString = string.replace(/-/g, " ");

    return formattedString
      .split(" ")
      .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
      .join(" ");
  };
  useEffect(() => {
    // Check if postId and editSource exist in localStorage
    const postId = localStorage.getItem("postId");
    const editSource = localStorage.getItem("editSource");

    if (postId && editSource === "edited from home") {
      // If they exist, set the modal visibility to true
      setIseditModalVisible(true);

      // Optionally, clear these items from localStorage after use
      localStorage.removeItem("postId");
      localStorage.removeItem("editSource");
    }
  }, [setIseditModalVisible]);
  const handleshare = (id) => {
    setUrl(`${window.location.origin}/post/${id}`);
    setcopyModalVisible(true);
    // navigate(`/post/${id}`);
  };
  const goToPostRelatedChatLists = (postId) => {
    navigate(`/message-for-post/${postId}`);
  };
  return (
    <div className="">
      {displayedPosts.map((post, index) => (
        <div
          key={post.id}
          className="w-full scrollbar-hide mt-2 "
          style={{
            backgroundColor:
              post.subscripiton_status === 1 && post.title === "job-seeker"
                ? "white"
                : post.subscripiton_status === 1
                ? "white"
                : "white",

            color:
              post.subscripiton_status === 1 || post.user?.username === "@Test"
                ? "black"
                : "black",
            padding:
              post.subscripiton_status === 1
                ? "0"
                : post.user?.username === "@Test"
                ? "0"
                : "0 0rem",
            borderRadius:
              post.subscripiton_status === 1 || post.user?.username === "@Test"
                ? "0"
                : "0.5rem",
            paddingTop: post.subscripiton_status === 1 ? "2px" : "0",
            paddingBottom: post.subscripiton_status === 1 ? "2px" : "0",
            marginBottom: post.user?.username === "@Test" ? "10px" : "0",
          }}
        >
          <div
            className={`flex w-full ${
              post.subscripiton_status === 1 ? " px-8 mt-6" : "px-8 mt-10"
            }`}
          >
            {post.user?.profile_image &&
              post.user?.profile_image !== "default.png" &&
              post.user?.profile_image !== "" && (
                <img
                  className={`w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] cursor-pointer ${
                    post.user?.type?.toLowerCase() === "employer"
                      ? "ring-4 ring-[#E7831D]"
                      : "ring-4 ring-neutral-700"
                  }`}
                  onClick={() => navigate(`/user/${post.user?.id}`)}
                  src={`https://bocaendboca.s3.us-east-2.amazonaws.com/${post.user?.profile_image}`}
                  alt="User Avatar"
                  style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                />
              )}
            {(!post.user?.profile_image ||
              post.user?.profile_image === "default.png" ||
              post.user?.profile_image === "") && (
              <FaUserCircle
                className={`w-12 h-12 text-[2rem] rounded-full p-0 object-cover text-neutral-300 cursor-pointer ${
                  post.user?.type?.toLowerCase() === "employer"
                    ? "ring-4 ring-[#E7831D]"
                    : "ring-4 ring-neutral-700"
                }`}
                onClick={() => navigate(`/user/${post.user?.id}`)}
              />
            )}
            <div className="flex flex-col ml-2">
              <Link
                to={`/user/${post.user.id}`}
                className={`text-lg  font-bold text-[#5B5B5B] ${
                  post.subscripiton_status === 1 ||
                  post.user.username === "@Test"
                    ? ""
                    : ""
                }`}
              >
                {post.user.first_name} {post.user.last_name}
              </Link>
              <span
                className={`lg:text-sm md:text-sm text-xs  flex items-center ${
                  post.user.type === "job-seeker"
                    ? "text-black"
                    : "text-[#E7831D]"
                }`}
              >
                <span
                  className={`text-sm   text-[#5B5B5B] ${
                    post.subscripiton_status === 1 ||
                    post.user.username === "@Test"
                      ? ""
                      : ""
                  }`}
                >
                  {" "}
                  {post.user.username}{" "}
                </span>
                <GoDotFill className="ml-1 text-xs mr-1" />
                {selectedLanguage === "English"
                  ? capitalizeFirstLetter(post.user.type)
                  : capitalizeFirstLetter(post.user.type_sp)}
                {post.subscripiton_status === 1 && (
                  <IoIosCheckmarkCircle className="ml-1 text-xl" />
                )}
              </span>
            </div>
            {/* <span className="text-neutral-600 ml-2 block md:hidden lg:hidden text-xs  mt-2">
              {post.user.username}
            </span> */}
            <MenuOptionscopy
              userExists={userExists}
              post={post}
              options={options}
              loading={loading}
              setIsLoginModalVisible={setIsLoginModalVisible}
              handleEditPost={handleEditPost}
              handleDeletePost={handleDeletePost}
              handleReportPost={handleReportPost}
              handleBookmarkClick={handleBookmarkClick}
              handleRepost={handleRepost}
            />
          </div>
          <PostDescription
            description={post.description}
            handleTagClick={handleTagClick}
          />
          {post.link_post_details && (
            <Repost Repostdatas={post.link_post_details} />
          )}

          <ImageGallery post={post} openModal={openModal} />
          {/* <VideoGallery post={post} openModal={openModal} /> */}

          {post.link_post_details ? (
            <div className="flex px-0 w-44  ml-auto mt-14 mb-5 mr-5">
              {localStorage.getItem("userId") !== post.user?.id && (
                <>
                  {!userExists ? (
                    <SvgIcon
                      src={commentIcon}
                      className="h-6 w-1/4 shrink-0 hover:scale-95 cursor-pointer ml-auto opacity-40"
                      aria-hidden="true"
                      onClick={() => setIsLoginModalVisible(true)}
                    />
                  ) : (
                    <div>
                      {channelLoading[post.id] ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          className="ml-auto w-2/4 text-neutral-500"
                        />
                      ) : (
                        <SvgIcon
                          src={commentIcon}
                          className="h-6  shrink-0 hover:scale-105 cursor-pointer ml-auto"
                          aria-hidden="true"
                          onClick={() =>
                            createChatChannel(
                              post.link_post_details.id,
                              post.link_post_details.user_id
                            )
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              )}
              {localStorage.getItem("userId") == post.user?.id && (
                <div
                  className="relative cursor-pointer hover:scale-105"
                  onClick={() =>
                    goToPostRelatedChatLists(post.link_post_details.id)
                  }
                >
                  <SvgIcon src={commentIcon} aria-hidden="true" />
                  {post.link_post_details.channels_count > 0 && (
                    <div className="absolute -top-2 -right-2 ml-auto bg-[#E7831D] rounded-full w-5 h-5 flex items-center justify-center">
                      <span className="text-white text-xs ">
                        {post.link_post_details.channels_count}
                      </span>
                    </div>
                  )}
                </div>
              )}

              {localStorage.getItem("token") && (
                <>
                  {bookMarkFetching[post.link_post_details.id] && (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      className="ml-auto w-2/4 text-neutral-500"
                    />
                  )}
                  {!bookMarkFetching[post.link_post_details.id] && (
                    <>
                      {post.bookmark_status.length > 0 ||
                      post.link_post_details.bookMarked ? (
                        <IoBookmarkSharp
                          className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookMarkdelete(
                              post.link_post_details.id,
                              index
                            );
                          }}
                        />
                      ) : (
                        <SvgIcon
                          src={
                            post.subscripiton_status === 1 ||
                            post.user?.username === "@Test"
                              ? bookmark
                              : bookmark
                          }
                          className="h-6 w-2/4 hover:scale-105 ml-auto cursor-pointer"
                          aria-hidden="true"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookMark(post.link_post_details.id, index);
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {!localStorage.getItem("token") && (
                <SvgIcon
                  src={bookmark} // Use the unbookmarked icon
                  className="h-6 w-2/4 opacity-40 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setIsLoginModalVisible(true)}
                />
              )}

              <LuShare
                className={`w-1/4 text-2xl text-neutral-700 cursor-pointer `}
                onClick={() => handleshare(post.link_post_details.id)}
              />
            </div>
          ) : (
            <div className="flex px-0 w-44  ml-auto mt-14 mb-5 mr-5">
              {localStorage.getItem("userId") !== post.user?.id && (
                <>
                  {!userExists ? (
                    <SvgIcon
                      src={
                        post.subscripiton_status === 1 ||
                        post.user?.username === "@Test"
                          ? commentIcon
                          : commentIcon
                      }
                      className="h-6 w-1/4 shrink-0 hover:scale-95 cursor-pointer ml-auto opacity-40"
                      aria-hidden="true"
                      onClick={() => setIsLoginModalVisible(true)}
                    />
                  ) : (
                    <div>
                      {channelLoading[post.id] ? (
                        <Spin
                          indicator={<LoadingOutlined spin />}
                          className="ml-auto w-2/4 text-neutral-500"
                        />
                      ) : (
                        <SvgIcon
                          src={
                            post.subscripiton_status === 1 ||
                            post.user?.username === "@Test"
                              ? commentIcon
                              : commentIcon
                          }
                          className="h-6  shrink-0 hover:scale-105 cursor-pointer ml-auto"
                          aria-hidden="true"
                          onClick={() =>
                            createChatChannel(post.id, post.user?.id)
                          }
                        />
                      )}
                    </div>
                  )}
                </>
              )}
              {localStorage.getItem("userId") == post.user?.id && (
                <div
                  className="relative cursor-pointer hover:scale-105"
                  onClick={() => goToPostRelatedChatLists(post.id)}
                >
                  <SvgIcon
                    src={
                      post.subscripiton_status === 1 ||
                      post.user?.username === "@Test"
                        ? commentIcon
                        : commentIcon
                    }
                    aria-hidden="true"
                  />
                  {post.channels_count > 0 && (
                    <div className="absolute -top-2 -right-2 ml-auto bg-[#E7831D] rounded-full w-5 h-5 flex items-center justify-center">
                      <span className="text-white text-xs ">
                        {post.channels_count}
                      </span>
                    </div>
                  )}
                </div>
              )}

              {localStorage.getItem("token") && (
                <>
                  {bookMarkFetching[post.id] && (
                    <Spin
                      indicator={<LoadingOutlined spin />}
                      className="ml-auto w-2/4 text-neutral-500"
                    />
                  )}
                  {!bookMarkFetching[post.id] && (
                    <>
                      {post.bookmark_status.length > 0 || post.bookMarked ? (
                        <IoBookmarkSharp
                          className="ml-auto w-2/4 text-2xl cursor-pointer hover:scale-105"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookMarkdelete(post.id, index);
                          }}
                        />
                      ) : (
                        <SvgIcon
                          src={
                            post.subscripiton_status === 1 ||
                            post.user?.username === "@Test"
                              ? bookmark
                              : bookmark
                          }
                          className="h-6 w-2/4 hover:scale-105 ml-auto cursor-pointer"
                          aria-hidden="true"
                          onClick={(e) => {
                            e.stopPropagation();
                            handleBookMark(post.id, index);
                          }}
                        />
                      )}
                    </>
                  )}
                </>
              )}

              {!localStorage.getItem("token") && (
                <SvgIcon
                  src={bookmark} // Use the unbookmarked icon
                  className="h-6 w-2/4 opacity-40 cursor-pointer"
                  aria-hidden="true"
                  onClick={() => setIsLoginModalVisible(true)}
                />
              )}

              <LuShare
                className={`w-1/4 text-2xl text-neutral-700 cursor-pointer `}
                onClick={() => handleshare(post.id)}
              />
            </div>
          )}

          {post.user.username !== "@premium" &&
            post.user.username !== "@Test" && <hr />}
        </div>
      ))}

      <MediaModal
        isModalOpen={isModalOpen}
        closeModal={closeModal}
        modalData={modalData}
        handleTagClick={handleTagClick}
      />
      <EditPostModalComponent
        setIseditModalVisible={setIseditModalVisible}
        iseditModalVisible={iseditModalVisible}
        fetchPosts={fetchPosts}
        fetchData={fetchData}
        setPostLimit={setPostLimit}
        setLoadingpost={setLoadingpost}
      />
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
      {copymodalVisible && (
        <SharePostPopUp
          url={url}
          copymodalVisible={copymodalVisible}
          setcopyModalVisible={setcopyModalVisible}
        />
      )}
    </div>
  );
};

export default PostForMyProfile;
