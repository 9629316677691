import React, { useState } from "react";
import { Modal, Space, Spin } from "antd";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import moment from "moment";
import { useSelector } from "react-redux";
import { IoClose } from "react-icons/io5";
import axios from "axios";
import { LoadingOutlined } from "@ant-design/icons";

const ConfirmInterviewModal = ({
  visible,
  interviewDate,
  interviewTime,
  onCancel,
  interviewType,
  message_id, // Add msgId to be passed for the API call
}) => {
  const [loadingTime, setLoadingTime] = useState(null);
  const navigate = useNavigate();
  const { id } = useParams();
  const selectedLanguage = useSelector((state) => state.language.language);

  // Format date using Moment.js
  const formattedDate = moment(interviewDate).format("DD MMM YYYY");

  // Split multiple times by commas
  const timesArray = interviewTime.split(",");

  const handleCancel = () => {
    onCancel();
  };

  // Handle button click and make API call
  const handleTimeClick = async (displayTime) => {
    setLoadingTime(displayTime);
    const token = localStorage.getItem("token");
    const timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    let channelId;
    const pathname = window.location.pathname;
    const dmMatch = pathname.match(/\/dm\/([^\/]+)/);

    if (dmMatch && dmMatch[1]) {
      channelId = dmMatch[1];
    } else {
      channelId = localStorage.getItem("channelId");
    }

    const apiTimeFormat = moment(displayTime, "h:mm a").format("HH:mm:ss");

    try {
      await axios.post(
        `${baseUrl}/api/v1/customer/chat/interview-status-change`,
        {
          id: message_id,
          interview_time: apiTimeFormat,
          interview_confirm: 1,
          interview_type: interviewType, // Ensure interviewType is passed correctly
          interview_date: interviewDate, // Ensure interviewDate is passed correctly
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Time-Zone": timeZone,
          },
        }
      );
      // Refresh the page on success
      window.location.reload();
    } catch (error) {
      console.error("Error confirming time:", error);
    } finally {
      setLoadingTime(null);
    }
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCancel}
      title={null}
      footer={null}
      closeIcon={<IoClose className="text-neutral-50" />}
    >
      <div className="flex flex-col space-y-5 items-center text-center lg:p-5 md:p-5 p-3">
        <p className="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          Are you available for {interviewType} on {formattedDate}?
        </p>
        <p className="font-inter font-semibold text-2xl leading-7 text-center px-12 py-5 mt-2">
          Please click the time below to confirm.
        </p>

        {/* Flex container for buttons */}
        <div className="flex space-x-4 w-full justify-center">
          {timesArray.map((time, index) => {
            const formattedTime = moment(time, "HH:mm:ss").format("h:mm A");
            return (
              <button
                key={index}
                className="h-14 bg-[#404041] text-white font-bold rounded-full shadow-sm text-xl hover:scale-95 hover:shadow-lg w-10/12"
                onClick={() => handleTimeClick(formattedTime)} // Call handleTimeClick on button click
                disabled={loadingTime === formattedTime} // Disable button while loading
              >
                {loadingTime === formattedTime ? (
                  <Space className="mr-3">
                    <Spin
                      indicator={
                        <LoadingOutlined
                          style={{ fontSize: 24, color: "#fff" }}
                          spin
                        />
                      }
                      size="medium"
                    />
                  </Space>
                ) : (
                  formattedTime
                )}
              </button>
            );
          })}
        </div>
      </div>
    </Modal>
  );
};

export default ConfirmInterviewModal;
