import React from "react";
import notfilled from "../../assets/fillcheck (2).svg";
const UpgradePlan = ({ textsExtra, subscriptionData }) => {
  return (
    <div
      className={`mt-4 w-11/12 mx-auto  shadow-[rgba(0,_0,_0,_0.24)_0px_1px_2px] rounded-2xl flex items-center text-center flex-col space-y-8 py-4`}
    >
      <img src={notfilled} alt="" />
      {subscriptionData.price === "9.99" ? (
        <>
          <h2 className={`text-xl font-extrabold text-[#404041] `}>
            {textsExtra.Upgrade}
          </h2>
          <p
            className={`text-xs lg:text-sm   px-14  text-justify text-[#404041]  `}
          >
            {textsExtra.UpgradeText}
          </p>
        </>
      ) : (
        <>
          <h2 className={`text-xl font-extrabold text-[#404041] `}>
            {textsExtra.Downgrade}
          </h2>
          <p
            className={`text-xs lg:text-sm   px-14  text-justify text-[#404041]  `}
          >
            {textsExtra.DowngradeText}
          </p>
        </>
      )}
      {subscriptionData.price === "999.00" && (
        <span className="text-xl font-bold text-[#E7831D]">$9.99/Year</span>
      )}

      {subscriptionData.price === "9.99" ? (
        <button
          className={`h-14 flex justify-between items-center  bg-[#404041] text-white text-sm lg:px-10 md:px-10 px-10 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
          // Pass subscription data
        >
          {textsExtra.UpgradeButton} <span>$ 999 /Year</span>
        </button>
      ) : (
        <button
          className={`h-14 flex justify-between items-center   text-center  bg-[#404041] text-white text-sm lg:px-20 md:px-10 px-10 font-bold rounded-full shadow-sm w-8/12 mb-3 hover:scale-95 hover:shadow-lg`}
          // Pass subscription data
        >
          {textsExtra.DowngradeButton}
        </button>
      )}
    </div>
  );
};

export default UpgradePlan;
