import React, { useState, useEffect } from "react";
import axios from "axios";
import avatar from "../../assets/avatarpost.png";
import { MdOutlineAddPhotoAlternate } from "react-icons/md";
import { FaTimes, FaUserCircle } from "react-icons/fa";
import { Toaster, toast } from "sonner";
import MainLogoLoader from "../loading/MainLogoLoader";
import PleaseLoginModal from "../../pages/PleaseLoginModal";
import { LoadingOutlined } from "@ant-design/icons";
import { Space, Spin } from "antd";
import { GoPaperclip } from "react-icons/go";
import { useDispatch, useSelector } from "react-redux";
import translations from "../../languages";

const PublishPost = ({
  fetchPosts,
  setLoadingpost,
  userExists,
  handleNewPostCreation,
  setOffset,
}) => {
  const [title, setTitle] = useState("");
  const [description, setDescription] = useState("");
  const [mediaFiles, setMediaFiles] = useState([]);
  console.log(mediaFiles, "mediaFiles");
  const [loading, setLoading] = useState(false);
  const [loadingava, setLoadingava] = useState(false);
  const [userAvatar, setUserAvatar] = useState("");
  const [userData, setUserData] = useState([]);
  const [userType, setUserType] = useState([]);
  const [highlightStatus, setHighlightStatus] = useState("");
  console.log(highlightStatus, "highlightStatus");
  const [isLoginModalVisible, setIsLoginModalVisible] = useState(false);
  const selectedLanguage = useSelector((state) => state.language.language);

  const texts = translations[selectedLanguage].buttons;

  useEffect(() => {
    const fetchUserDetails = async () => {
      setLoadingava(true);
      try {
        const token = localStorage.getItem("token");
        const baseURL = process.env.REACT_APP_API_BASE_URL;
        const userDetailsUrl = `${baseURL}/api/v1/user/details`;
        const response = await axios.get(userDetailsUrl, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        });
        setUserAvatar(response.data.content.profile_image);
        setUserData(response.data.content);
        setHighlightStatus(
          response.data.content.subscription.plan.highlight_status
        );
        localStorage.setItem("userId", response.data.content.id);
        setLoadingava(false);
      } catch (error) {
        console.error("Error fetching user details:", error);
        setLoadingava(false);
      }
    };

    fetchUserDetails();
  }, []);

  const handleMediaUpload = (e) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    const files = Array.from(e.target.files);
    setMediaFiles((prevFiles) => [...prevFiles, ...files]);
  };

  const handleDescriptionChange = (e) => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }
    setDescription(e.target.value);
  };

  const removeMediaFile = (index) => {
    setMediaFiles((prevFiles) => prevFiles.filter((_, i) => i !== index));
  };

  const handleSubmit = async () => {
    if (!userExists) {
      setIsLoginModalVisible(true);
      return;
    }

    // Validation logic
    if (mediaFiles.length === 0 && !description.trim()) {
      toast("Please add a description or media to your post.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      return;
    }

    const token = localStorage.getItem("token");
    const baseURL = process.env.REACT_APP_API_BASE_URL;
    const url = `${baseURL}/api/v1/user/post/store`;

    const formData = new FormData();
    setLoading(true);
    setLoadingpost(true);

    const titleToSubmit = highlightStatus === "yes" ? userData.type : title;

    formData.append("title", titleToSubmit);

    formData.append("description", description);

    mediaFiles.forEach((file, index) => {
      if (file.type.startsWith("image/")) {
        formData.append("identity_images[]", file);
      } else if (file.type.startsWith("video/")) {
        formData.append("video[]", file);
      }
    });

    const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

    try {
      const response = await axios.post(url, formData, {
        headers: {
          Authorization: `Bearer ${token}`,
          "Content-Type": "multipart/form-data",
          "Time-Zone": userTimeZone,
        },
      });

      if (response.status === 200) {
        setLoading(false);
        toast("Post published successfully!", {
          style: {
            backgroundColor: "#333",
            color: "#fff",
          },
        });

        setTitle("");
        setDescription("");
        setMediaFiles([]);
        handleNewPostCreation();
        setOffset(1);
        // window.location.reload();
      } else {
        setLoading(false);
        setLoadingpost(false);
        toast("Failed to publish post", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      setLoading(false);
      setLoadingpost(false);

      if (
        error.response &&
        error.response.data.error === "Only 4 images are allowed."
      ) {
        toast("You can upload only up to 4 images.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } else if (error.response && error.response.data.errors) {
        const errorMessages = error.response.data.errors
          .map((err) => err.message)
          .join(", ");
        toast(`Error: ${errorMessages}`, {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } else {
        console.error("Error publishing post:", error);
        toast("An unexpected error occurred.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      }
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === "Enter" && !e.shiftKey) {
      e.preventDefault(); // Prevents the default action of sending the message
      handleSubmit(); // Call the submit function
    }
  };

  return (
    <>
      <div className="flex flex-col mt-3 w-full mb-3 px-8">
        {loading && (
          <div className="absolute inset-0 flex items-center justify-center bg-white/50 bg-opacity-50 z-50">
            <MainLogoLoader />
          </div>
        )}

        <div className="flex flex-col sm:flex-row items-start">
          {loadingava ? (
            <div className="w-12 h-12 rounded-full p-0 object-cover text-[#E7831D] mb-3 sm:mb-0 bg-neutral-300 animate-pulse mt-3"></div>
          ) : (
            <>
              {userAvatar ? (
                <img
                  className={`w-12 h-12 mt-4 rounded-full p-0 object-cover text-[#E7831D] cursor-pointer 
                ${
                  userData.type === "employer"
                    ? "ring-4 ring-[#E7831D]"
                    : "ring-4 ring-neutral-700"
                }
              `}
                  src={
                    userAvatar !== "default.png" && userAvatar
                      ? `https://bocaendboca.s3.us-east-2.amazonaws.com/${userAvatar}`
                      : avatar
                  }
                  alt="User Avatar"
                  style={{ aspectRatio: "1 / 1", objectFit: "cover" }}
                />
              ) : (
                <FaUserCircle
                  className={`w-12 h-12 text-[2rem] mt-4 rounded-full p-0 object-cover text-neutral-300 cursor-pointer `}
                />
              )}
            </>
          )}

          <div className="w-full h-auto flex flex-col relative">
            {!description && (
              <div className="w-full absolute h-20 bg-transparent text-neutral-400 left-5 top-5 z-20">
                Hello, I am looking for work as a{" "}
                <span className="text-[#E7831D]">#cook</span> in{" "}
                <span className="text-[#E7831D]">#Houston</span> <br />
                <span className="text-[#E7831D]">#Texas </span> ...
              </div>
            )}

            <textarea
              type="text"
              value={description}
              onChange={handleDescriptionChange}
              onKeyDown={handleKeyDown}
              placeholder=""
              className="border-0 outline-none p-5 w-full z-50 bg-transparent"
              style={{ minHeight: "100px", overflow: "hidden" }}
              onInput={(e) => {
                e.target.style.height = "auto";
                e.target.style.height = `${e.target.scrollHeight}px`;
              }}
            />
            <div className="flex flex-wrap mt-3 gap-2">
              {mediaFiles.map((file, index) => (
                <div key={index} className="relative">
                  {file.type.startsWith("image/") ? (
                    <img
                      src={URL.createObjectURL(file)}
                      alt={`upload-${index}`}
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  ) : (
                    <video
                      src={URL.createObjectURL(file)}
                      controls
                      className="w-20 h-20 object-cover rounded-md"
                    />
                  )}
                  <FaTimes
                    onClick={() => removeMediaFile(index)}
                    className="absolute top-1 right-1 text-white cursor-pointer bg-[#E7831D] rounded-full"
                  />
                </div>
              ))}
            </div>
          </div>
        </div>

        <div className="flex w-full h-auto mt-5 gap-2 items-center justify-end">
          {localStorage.getItem("token") ? (
            <>
              <input
                type="file"
                accept="image/*,video/*"
                multiple
                onChange={handleMediaUpload}
                style={{ display: "none" }}
                id="media-upload"
              />
              <label htmlFor="media-upload">
                <MdOutlineAddPhotoAlternate className="text-3xl cursor-pointer" />
              </label>
            </>
          ) : (
            <div>
              <MdOutlineAddPhotoAlternate
                className="text-3xl cursor-pointer"
                onClick={handleSubmit}
              />
            </div>
          )}

          <button
            onClick={handleSubmit}
            className=" bg-[#404041] h-14 flex items-center justify-center text-center text-white font-bold rounded-full px-10 hover:bg-[#545455] hover:scale-95 text-lg"
          >
            {loading && (
              <Space className="mr-2">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 14, color: "#fff" }}
                      spin
                    />
                  }
                  size="medium"
                />
              </Space>
            )}
            {texts.publish}
          </button>
        </div>
      </div>
      {isLoginModalVisible && (
        <PleaseLoginModal
          visible={isLoginModalVisible}
          onCancel={() => setIsLoginModalVisible(false)}
        />
      )}
    </>
  );
};

export default PublishPost;
