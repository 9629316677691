import React, { useState } from "react";
import { Modal, Spin, Space, message } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import axios from "axios";
import { IoClose } from "react-icons/io5";

const CancelInterviewModal = ({ visible, close, message_id }) => {
  const [loadingSubmit, setLoadingSubmit] = useState(false);

  const handleCancelInterview = async ({}) => {
    const token = localStorage.getItem("token");
    // const message_id = localStorage.getItem("message_id"); // Assuming interview_id is stored in localStorage
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
    const formData = new FormData();

    formData.append("id", message_id || ""); // Use the interview ID
    formData.append("interview_confirm", "2"); // Status for cancellation

    setLoadingSubmit(true);

    try {
      await axios.post(
        `${baseUrl}/api/v1/customer/chat/interview-status-change`,
        formData,
        {
          headers: {
            Authorization: `Bearer ${token}`,
            "Time-Zone": timezone,
          },
        }
      );

      //   message.success("Interview canceled successfully");
      close();
      //   window.location.reload();
    } catch (error) {
      console.error("Error canceling interview:", error);
      //   message.error("Failed to cancel the interview. Please try again.");
    } finally {
      setLoadingSubmit(false);
      localStorage.removeItem("status");
    }
  };

  const handleCloseModal = () => {
    localStorage.removeItem("status");
    close();
  };

  return (
    <Modal
      visible={visible}
      onCancel={handleCloseModal}
      footer={null}
      centered
      closeIcon={<IoClose className="text-neutral-50" />}
      className="cancel-interview-modal "
    >
      <div className="lg:flex lg:h-full lg:flex-col text-center p-5">
        <p className="text-2xl font-bold mt-10">
          Do you want to cancel this interview?
        </p>
        <div className="flex gap-3 mt-4">
          <button
            className="p-3 w-full bg-white text-[#404041] border border-[#404041] font-bold rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-lg mt-4 flex items-center justify-center"
            onClick={handleCloseModal}
          >
            <span>No, don’t</span>
          </button>
          <button
            className="p-3 w-full bg-[#404041] text-white font-bold rounded-full shadow-sm text-lg hover:scale-95 hover:shadow-lg mt-4 flex items-center justify-center"
            onClick={handleCancelInterview}
            disabled={loadingSubmit}
          >
            {loadingSubmit ? (
              <Space className="mr-3">
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{ fontSize: 28, color: "#fff" }}
                      spin
                    />
                  }
                  size="medium"
                />
              </Space>
            ) : (
              <span>Yes, Cancel</span>
            )}
          </button>
        </div>
      </div>
    </Modal>
  );
};

export default CancelInterviewModal;
