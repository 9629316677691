import React, { useEffect, useState } from "react";
import { FaLinkedin, FaFacebookF, FaGoogle } from "react-icons/fa";
import { BsApple } from "react-icons/bs";
import { GoogleOAuthProvider, useGoogleLogin } from "@react-oauth/google";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import { useLinkedIn } from "react-linkedin-login-oauth2";
import { Toaster, toast } from "sonner";
import SccialLoading from "../loading/SccialLoading";
import RoleSelectionModal from "./RoleSelectionModal";

const SocialLogin = () => {
  const navigate = useNavigate();
  const baseURL = process.env.REACT_APP_API_BASE_URL;
  const [phoneNumber, setPhoneNumber] = useState("");
  const [loading, setLoading] = useState(false);
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [selectedRole, setSelectedRole] = useState(null);
  const [selectedStateId, setSelectedStateId] = useState(null);
  const [selectedCityId, setSelectedCityId] = useState(null);
  const [userInfo, setUserInfo] = useState({});
  const url = `${window.location.origin}/linkedin/callback`;
  console.log(url, "url");

  useEffect(() => {
    if (!window.FB) {
      window.fbAsyncInit = function () {
        windselectedStateIdow.FB.init({
          appId: process.env.REACT_APP_FACEBOOK_APP_ID,
          cookie: true,
          xfbml: true,
          version: "v11.0",
        });
      };

      (function (d, s, id) {
        const fjs = d.getElementsByTagName(s)[0];
        if (d.getElementById(id)) return;
        const js = d.createElement(s);
        js.id = id;
        js.src = "https://connect.facebook.net/en_US/sdk.js";
        fjs.parentNode.insertBefore(js, fjs);
      })(document, "script", "facebook-jssdk");
    }
  }, []);

  const handleFacebookLogin = async () => {
    setLoading(true);
    try {
      const fbResponse = await new Promise((resolve, reject) => {
        window.FB.login(
          (response) => {
            if (response.authResponse) {
              resolve(response);
            } else {
              reject("User cancelled login or did not fully authorize.");
            }
          },
          { scope: "email,public_profile" }
        );
      });

      const { accessToken, userID } = fbResponse.authResponse;

      const userInfo = await axios.get(
        `https://graph.facebook.com/v11.0/me?fields=id,name,email&access_token=${accessToken}`
      );

      const { email, name, id: unique_id } = userInfo.data;
      const [first_name, last_name] = name.split(" ");

      // Check if user already exists
      const checkUserResponse = await axios.post(
        `${baseURL}/api/v1/customer/auth/social-login`,
        { email, unique_id, fcm_token: "" }
      );

      if (checkUserResponse.status === 200) {
        const { token } = checkUserResponse.data.content;
        localStorage.setItem("token", token);
        navigate("/");
        toast("Facebook login successful!", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      } else {
        // Proceed with social registration
        const requestBody = {
          first_name,
          last_name,
          email,
          unique_id,
          medium: "facebook",
          type: "job_seeker",
        };

        const apiResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-registration`,
          requestBody
        );

        const { token } = apiResponse.data.content;
        localStorage.setItem("token", token);
        navigate("/");
        toast("Facebook login successful!", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
      }
    } catch (error) {
      console.error("Error during Facebook login:", error);
      toast("Facebook login failed. Please select your role.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
      setIsModalVisible(true);
    } finally {
      setLoading(false);
    }
  };

  // Google Login
  const handleGoogleLogin = useGoogleLogin({
    onSuccess: async (response) => {
      setLoading(true);
      try {
        const userInfoResponse = await axios.get(
          `https://www.googleapis.com/oauth2/v3/userinfo`,
          {
            headers: {
              Authorization: `Bearer ${response.access_token}`,
            },
          }
        );

        const {
          given_name: first_name,
          family_name: last_name,
          email,
          sub: unique_id,
        } = userInfoResponse.data;

        // Store user information in state
        setUserInfo({ first_name, last_name, email, unique_id });

        // Check if user already exists
        const checkUserResponse = await axios.post(
          `${baseURL}/api/v1/customer/auth/social-login`,
          { email, unique_id, fcm_token: "" }
        );

        if (checkUserResponse.status === 200) {
          const { token } = checkUserResponse.data.content;
          localStorage.setItem("token", token);
          navigate("/");
        } else {
          // Show role selection modal if user does not exist
          setIsModalVisible(true);
          setSelectedRole(null);
        }
      } catch (error) {
        console.error("Error during Google login:", error);
        toast("Google login failed. Please select your role.", {
          style: { backgroundColor: "#333", color: "#fff" },
        });
        setIsModalVisible(true);
      } finally {
        setLoading(false);
      }
    },
    onError: () => {
      toast("Google login failed. Please try again.", {
        style: { backgroundColor: "#333", color: "#fff" },
      });
    },
  });

  // Function to handle role selection
  const handleRoleSelect = async (role) => {
    setSelectedRole(role);

    // Check if both state and city are selected before proceeding
    if (selectedStateId && selectedCityId) {
      // Use userInfo state to access user details
      const { first_name, last_name, email, unique_id } = userInfo;

      // Proceed with social registration
      const requestBody = {
        first_name,
        last_name,
        email,
        unique_id,
        medium: "google",
        type: role,
        country_id: "233",
        state_id: String(selectedStateId), // Ensuring state is a string
        city_id: String(selectedCityId), // Ensuring city is a string
      };

      const apiResponse = await axios.post(
        `${baseURL}/api/v1/customer/auth/social-registration`,
        requestBody
      );

      const { token } = apiResponse.data.content;
      localStorage.setItem("token", token);
      navigate("/");
      toast("Registration successful!", {
        style: { backgroundColor: "#333", color: "#fff" },
      });

      // Close the modal after successful registration
      setIsModalVisible(false);
    }
  };

  if (loading) {
    return <SccialLoading />;
  }

  return (
    <div className="w-1/2 h-auto flex justify-between items-center px-10 mt-5 mx-auto">
      <FaLinkedin
        onClick={() => navigate("/backend/linkedin/login")}
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
      <FaFacebookF
        onClick={() => navigate("/backend/facebook/login")}
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
      <FaGoogle
        onClick={handleGoogleLogin}
        className="text-4xl text-[#414040] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer"
      />
      {/* <BsApple className="text-4xl text-[#404041] hover:scale-105 transition-transform ease-in-out duration-300 cursor-pointer" /> */}

      <Toaster position="top-right" />
      <RoleSelectionModal
        visible={isModalVisible}
        onClose={() => setIsModalVisible(false)}
        onSelectRole={handleRoleSelect}
        setSelectedStateId={setSelectedStateId}
        setSelectedCityId={setSelectedCityId}
        setPhoneNumber={setPhoneNumber}
        phoneNumber={phoneNumber}
      />
    </div>
  );
};

export default SocialLogin;
