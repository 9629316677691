import React, { useState, useEffect } from "react";
import moment from "moment";
import axios from "axios";
import { useNavigate } from "react-router-dom";
import {
  Popover,
  PopoverButton,
  PopoverPanel,
  Transition,
} from "@headlessui/react";
import { BsThreeDots } from "react-icons/bs";
import { toast } from "sonner";

const MenuOptionsChat = ({ userid, chatId }) => {
  const [myPost, setMyPost] = useState("");
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    const MyId = localStorage.getItem("userId");
    setMyPost(MyId);
  }, []);

  const handleUserAction = async (action) => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token");

    try {
      const response = await axios.post(
        `${baseUrl}/api/v1/customer/chat/user-action`,
        {
          message: action,
          user_id: userid,
          comment_type: action,
        },
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast(`User ${action.charAt(0).toUpperCase() + action.slice(1)} `, {
          style: {
            backgroundColor: "#333",
            color: "#fff",
          },
        });
        navigate("/messages");
      }
    } catch (error) {
      console.error("Error performing user action:", error);
      toast("Error performing user action. Please try again later.", {
        style: {
          backgroundColor: "#333",
          color: "#fff",
        },
      });
    }
  };

  const handleDeleteChat = async () => {
    const baseUrl = process.env.REACT_APP_API_BASE_URL;
    const token = localStorage.getItem("token"); // Replace with the actual chat ID
    try {
      const response = await axios.get(
        `${baseUrl}/api/v1/customer/chat/delete-channel/${chatId}`,
        {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        }
      );
      if (response.status === 200) {
        toast("Chat deleted successfully", {
          style: {
            backgroundColor: "#333",
            color: "#fff",
          },
        });
        navigate("/messages");
      }
    } catch (error) {
      console.error("Error deleting chat:", error);
      toast("Error deleting chat. Please try again later.", {
        style: {
          backgroundColor: "#333",
          color: "#fff",
        },
      });
    }
  };

  return (
    <>
      <div className=" flex justify-end text-xs flex-col ml-auto relative top-0 z-50 ">
        <Popover>
          {({ open }) => {
            setIsMenuOpen(open);
            return (
              <>
                <PopoverButton className="border-none focus:outline-none">
                  <BsThreeDots className="text-3xl absolute right-0 cursor-pointer" />
                </PopoverButton>
                <Transition
                  enter="transition ease-out duration-75"
                  enterFrom="opacity-0 scale-95"
                  enterTo="opacity-100 scale-100"
                  leave="transition ease-in duration-100"
                  leaveFrom="opacity-100 scale-100"
                  leaveTo="opacity-0 scale-95"
                >
                  <PopoverPanel className="right-2 top-10 origin-top-right w-36  border border-black/30 bg-white  text-sm/6 text-black [--anchor-gap:var(--spacing-1)] focus:outline-none shadow-md absolute">
                    <button
                      onClick={() => handleUserAction("block")}
                      className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start whitespace-nowrap "
                    >
                      Block user
                    </button>

                    <button
                      onClick={() => handleUserAction("report")}
                      className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start  whitespace-nowrap"
                    >
                      Report user
                    </button>

                    <button
                      onClick={handleDeleteChat}
                      className="text-neutral-900 group flex  items-left w-full px-2 py-2 text-sm hover:bg-neutral-700 hover:text-white justify-start"
                    >
                      Delete chat
                    </button>
                  </PopoverPanel>
                </Transition>
              </>
            );
          }}
        </Popover>
        {/* <p>{getTimeAgo(post.created_at)}</p> */}
      </div>
    </>
  );
};

export default MenuOptionsChat;
