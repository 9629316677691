import { createSlice } from "@reduxjs/toolkit";

const subscriptionSlice = createSlice({
  name: "subscription",
  initialState: {
    selectedWebProductId: null,
    defaultPrice: null,
  },
  reducers: {
    setSelectedWebProductId: (state, action) => {
      state.selectedWebProductId = action.payload;
    },
    setDefaultPrice: (state, action) => {
      state.defaultPrice = action.payload;
    },
  },
});

export const { setSelectedWebProductId, setDefaultPrice } =
  subscriptionSlice.actions;
export default subscriptionSlice.reducer;
