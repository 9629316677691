import React from "react";

const PrivacySp = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-neutral-50 text-sm text-justify">
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-bold mb-4">Legal</h1>
        <p className="mb-4">
          Boca En Boca™ es una marca registrada de The Peddler Group, Inc., una
          corporación formada en Texas, establecida el 18 de diciembre de 2018.
        </p>
        <h1 className="text-xl font-bold mb-4">Política de Privacidad</h1>
        <p className="mb-4">Vigente a partir del 15 de septiembre de 2024</p>

        <ul className="list-decimal ml-5 mb-4">
          <li>Acerca de esta política</li>
          <li>Derechos y controles sobre sus datos personales</li>
          <li>Datos personales que recopilamos</li>
          <li>Finalidades del procesamiento de sus datos personales</li>
          <li>Divulgación de sus datos personales</li>
          <li>Conservación de datos</li>
          <li>Seguridad de los datos</li>
          <li>Privacidad infantil</li>
          <li>Cambios en esta política</li>
          <li>Resolución de disputas y acuerdo de arbitraje</li>
          <li>Información de contacto</li>
        </ul>

        <h3 className="text-lg font-bold mb-2">1. Acerca de esta política</h3>
        <p className="mb-4">
          Esta Política de Privacidad ('Política') describe cómo The Peddler
          Group, Inc. procesa los datos personales en relación con la aplicación
          móvil Boca En Boca™. Detalla nuestras prácticas con respecto a la
          recopilación, el uso y el manejo de su información.
        </p>
        <p className="mb-4">
          Esta política se aplica a:
          <ul className="list-disc ml-5 mt-2">
            <li>Su uso de todos los servicios de Boca En Boca™, incluyendo:</li>
            <li>Acceso a Boca En Boca™ en cualquier dispositivo</li>
            <li>Personalización de su experiencia de usuario</li>
            <li>Infraestructura que respalda nuestros servicios</li>
            <li>
              Integración de su cuenta de Boca En Boca™ con aplicaciones de
              terceros
            </li>
            <li>Tanto niveles de suscripción gratuitos como de pago</li>
            <li>
              Otros servicios de Boca En Boca™ que estén vinculados a esta
              política, como nuestros sitios web y plataformas de atención al
              cliente.
            </li>
          </ul>
        </p>
        <p className="mb-4">
          Usted acepta la Política de Privacidad establecida aquí mediante el
          uso del sitio web o la aplicación móvil de Boca En Boca, con o sin una
          cuenta registrada.
        </p>
        <p className="mb-4">
          En conjunto, estos se denominarán "Servicio Boca En Boca™".
        </p>
        <p className="mb-4">
          Esta política no cubre:
          <ul className="list-disc ml-5 mt-2">
            <li>
              Los Términos y Condiciones de Boca En Boca™, que son un acuerdo
              legal separado entre usted y The Peddler Group, Inc. que regula el
              uso del Servicio Boca En Boca™.
            </li>
            <li>
              Su uso de otros servicios de Boca En Boca™ que están sujetos a sus
              propias políticas de privacidad.
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">
          2. Derechos y controles sobre sus datos personales
        </h3>
        <p className="mb-4">
          Las leyes de privacidad aplicables le otorgan derechos específicos
          sobre sus datos personales. Como usuario del Servicio Boca En Boca™,
          usted tiene el derecho de:
          <ul className="list-disc ml-5 mt-2">
            <li>
              Ser informado sobre los datos personales que procesamos sobre
              usted
            </li>
            <li>Acceder a los datos personales que tenemos sobre usted</li>
            <li>Rectificar cualquier dato personal inexacto</li>
            <li>
              Solicitar la eliminación de sus datos personales en ciertas
              circunstancias
            </li>
            <li>
              Restringir u oponerse a un procesamiento específico de sus datos
              personales
            </li>
            <li>Solicitar la portabilidad de sus datos</li>
            <li>
              Retirar su consentimiento en cualquier momento, cuando el
              procesamiento se base en su consentimiento
            </li>
          </ul>
        </p>
        <p className="mb-4">
          Para ejercer estos derechos o para cualquier consulta relacionada, por
          favor, póngase en contacto con The Peddler Group, Inc. utilizando la
          información de contacto proporcionada al final de esta política.
        </p>

        <h3 className="text-lg font-bold mb-2">
          3. Datos personales que recopilamos
        </h3>
        <p className="mb-4">
          The Peddler Group, Inc. recopila las siguientes categorías de datos
          personales a través del Servicio Boca En Boca™:
        </p>
        <p className="mb-4">
          Datos de usuario - Información necesaria para crear su cuenta, que
          incluye:
          <ul className="list-disc ml-5 mt-2">
            <li>Nombre</li>
            <li>Dirección de correo electrónico</li>
            <li>Número de teléfono</li>
            <li>Fecha de nacimiento</li>
          </ul>
        </p>
        <p className="mb-4">
          Datos de uso - Información sobre su interacción con el Servicio Boca
          En Boca™, que incluye:
          <ul className="list-disc ml-5 mt-2">
            <li>Historial de búsqueda de empleo</li>
            <li>Interacciones con empleadores y/o buscadores de empleo</li>
            <li>Información del dispositivo</li>
          </ul>
        </p>
        <p className="mb-4">
          Datos de ubicación - Información general de ubicación (por ejemplo,
          país o ciudad) derivada de:
          <ul className="list-disc ml-5 mt-2">
            <li>Dirección IP</li>
            <li>Otra información que usted proporcione</li>
          </ul>
        </p>
        <p className="mb-4">
          Datos de comunicación - Mensajes intercambiados dentro del Servicio
          Boca En Boca™:
          <ul className="list-disc ml-5 mt-2">
            <li>Entre usuarios</li>
            <li>Con nuestro equipo de soporte al cliente</li>
          </ul>
        </p>
        <p className="mb-4">
          Datos de pago - Información necesaria para procesar transacciones de
          servicios pagos
        </p>

        <h3 className="text-lg font-bold mb-2">
          4. Finalidades del procesamiento de sus datos personales
        </h3>
        <p className="mb-4">
          The Peddler Group, Inc. procesa los datos personales recopilados a
          través del Servicio Boca En Boca™ para los siguientes fines:
          <ul className="list-disc ml-5 mt-2">
            <li>
              Provisión y personalización del servicio: Para ofrecer y adaptar
              el Servicio Boca En Boca™ a sus preferencias
            </li>
            <li>
              Mejora e innovación del servicio: Para mejorar los servicios
              existentes y desarrollar nuevas características basadas en
              interacciones de los usuarios y comentarios
            </li>
            <li>
              Gestión de cuentas y comunicación: Para facilitar notificaciones
              relacionadas con cuentas y proporcionar información sobre nuestros
              servicios
            </li>
            <li>
              Seguridad y funcionalidad de la plataforma: Para mantener la
              integridad, seguridad y correcto funcionamiento de la plataforma
              Boca En Boca™
            </li>
            <li>
              Cumplimiento legal: Para cumplir con leyes, regulaciones y
              obligaciones legales aplicables
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">
          5. Divulgación de Sus Datos Personales
        </h3>
        <p className="mb-4">
          The Peddler Group Inc. puede compartir los datos personales
          recopilados a través del Servicio Boca En Boca con las siguientes
          categorías de destinatarios:
          <ul className="list-disc mt-2">
            <p>
              Usuarios de la Plataforma - Empleadores y buscadores de empleo que
              usan el Servicio Boca En Boca™, en el contexto de:
              <p className="mt-2">Interacciones directas</p>
              <p>Búsqueda dentro de la plataforma</p>
            </p>
            <li className="ml-4 mt-1">
              Proveedores de Servicios - Entidades externas que ayudan en la
              operación y mantenimiento de nuestro negocio
            </li>
            <li className="ml-4">
              Empresas Afiliadas - Otras entidades dentro de la estructura
              corporativa de The Peddler Group, Inc.
            </li>
            <li className="ml-4">
              Autoridades Legales y Regulatorias - Agencias de la ley u otros
              cuerpos gubernamentales, según lo requieran las leyes o
              regulaciones aplicables
            </li>
            <li className="ml-4">
              Cessionarios Comerciales - Potenciales compradores o socios de
              fusión en caso de una venta, fusión o adquisición que involucre a
              The Peddler Group, Inc. o el Servicio Boca En Boca™
            </li>
          </ul>
        </p>

        <h3 className="text-lg font-bold mb-2">6. Retención de Datos</h3>
        <p className="mb-4">
          The Peddler Group Inc. retiene sus datos personales solo por el tiempo
          necesario para:
          <ul className="list-disc ml-5 mt-2">
            <li>Proveer el Servicio Boca En Boca™</li>
            <li>Cumplir con fines comerciales legítimos</li>
            <li>Cumplir con obligaciones legales</li>
          </ul>
          Los períodos de retención varían según el tipo de datos y los
          requisitos legales aplicables.
        </p>

        <h3 className="text-lg font-bold mb-2">7. Seguridad de los Datos</h3>
        <p className="mb-4">
          Para proteger sus datos personales, The Peddler Group, Inc. implementa
          medidas técnicas y organizativas adecuadas contra el acceso no
          autorizado, alteración, divulgación o destrucción. Sin embargo, tenga
          en cuenta que ningún método de almacenamiento o transmisión
          electrónica por Internet es 100% seguro.
        </p>

        <h3 className="text-lg font-bold mb-2">8. Privacidad de los Niños</h3>
        <p className="mb-4">
          El Servicio Boca En Boca™ no está destinado a individuos menores de 18
          años. The Peddler Group, Inc. no recopila intencionadamente datos
          personales de niños. Si nos damos cuenta de que se han recopilado
          datos de un niño menor de 18 años, tomaremos medidas inmediatas para
          eliminar dicha información.
        </p>

        <h3 className="text-lg font-bold mb-2">9. Cambios a Esta Política</h3>
        <p className="mb-4">
          The Peddler Group, Inc. puede actualizar esta Política de Privacidad
          periódicamente con o sin notificación a los usuarios.
        </p>

        <h3 className="text-lg font-bold mb-2">
          10. Resolución de Disputas y Acuerdo de Arbitraje
        </h3>
        <p className="mb-4">
          Al aceptar esta Política de Privacidad, usted reconoce y renuncia a
          cualquier derecho de emprender acciones legales contra The Peddler
          Group, Inc. relacionadas con o derivadas del Servicio Boca En Boca™ o
          esta Política de Privacidad. Además, usted acepta que cualquier
          resolución de disputas se llevará a cabo de manera individual, y
          renuncia al derecho de participar en cualquier acción colectiva.
        </p>
        <p className="mb-4">
          The Peddler Group, Inc. y Boca En Boca™ no son responsables de los
          honorarios legales que usted incurra. En el caso de que usted inicie
          acciones legales contra The Peddler Group, Inc. o Boca En Boca™, usted
          será el único responsable de cubrir todos los costos legales asociados
          con dicha acción para todas las partes involucradas.
        </p>
        <p className="mb-4">
          Al usar Boca En Boca™, ya sea con o sin una cuenta de usuario
          registrada, usted está entrando en un Acuerdo de Exoneración de
          Responsabilidad con The Peddler Group, Inc. y Boca En Boca™, eximiendo
          a ambos de cualquier responsabilidad por reclamos, daños o pérdidas
          derivadas de su uso del servicio.
        </p>

        <h3 className="text-lg font-bold mb-2">11. Información de Contacto</h3>
        <p className="mb-4">
          Para preguntas relacionadas con esta Política de Privacidad, por favor
          contacte a nuestro Oficial de Protección de Datos
          <a
            href="mailto:support@bocaenboca.com"
            className="text-orange-500 cursor-pointer ml-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@bocaenboca.com
          </a>
          .
        </p>
        <p className="mb-4">
          The Peddler Group, Inc. es el controlador de datos para los datos
          personales procesados bajo esta Política.
        </p>
      </div>
    </div>
  );
};

export default PrivacySp;
