import React from "react";

const RefundSp = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-neutral-50 text-sm text-justify">
      {/* About Us Section */}
      <div className="container mx-auto p-4">
        <h1 className="text-xl font-bold mb-4">Sobre Nosotros</h1>
        <p className="mb-4">
          Boca En Boca™ es una aplicación de contratación impulsada por la
          comunidad que busca revolucionar el proceso de búsqueda de empleo al
          facilitar conexiones directas entre empleadores y solicitantes de
          empleo. La aplicación ofrece una funcionalidad fluida para la búsqueda
          de empleo, junto con una herramienta de mensajería de texto directa,
          lo que permite conversaciones auténticas que conducen a mejores
          decisiones de contratación.
        </p>
        <p className="mb-4">
          El proceso de contratación actual depende en gran medida de la
          revisión de currículums y aplicaciones que no capturan el potencial
          completo de un candidato. Esto abrumaba a los empleadores con
          candidatos no calificados y frustraba a los solicitantes de empleo.
          Boca En Boca aborda esto priorizando diálogos e interacciones
          significativas.
        </p>
        <p className="mb-4">
          Ofrecemos una solución integral que combina funcionalidades de
          búsqueda de empleo con una herramienta de mensajería de texto directa.
          Los solicitantes pueden navegar por estas ofertas y comunicarse
          directamente con un solo clic. También facilitamos la configuración de
          entrevistas telefónicas, por video o presenciales dentro de nuestra
          herramienta de mensajería de texto directa.
        </p>
        <p className="mb-4">
          Nuestra herramienta de mensajería directa permite que los empleadores
          y los solicitantes de empleo participen en discusiones significativas,
          lo que permite a ambas partes evaluar la compatibilidad mutua y
          aclarar cualquier duda o preocupación. Este enfoque promueve la
          transparencia y ayuda a asegurar que el candidato adecuado se empareje
          con el trabajo adecuado.
        </p>
        <p className="mb-4">
          Imagina un escenario en el que un solicitante de empleo tenga
          preguntas sobre la cultura laboral de la empresa o un aspecto
          específico del puesto. Con Boca En Boca, simplemente pueden ponerse en
          contacto directamente con el empleador a través de nuestro sistema de
          mensajería y obtener respuestas inmediatas. De manera similar, los
          empleadores pueden evaluar las habilidades de comunicación de un
          candidato, su ajuste cultural y entusiasmo a través de estos
          intercambios.
        </p>
        <p className="mb-4">
          Al proporcionar las herramientas para tener estas interacciones
          genuinas, nuestro objetivo es reducir la frustración e ineficiencia
          que a menudo acompaña al proceso de contratación actual, que está
          roto. Sabemos que encontrar el ajuste perfecto va más allá de un
          currículum o solicitud, y por eso ofrecemos una plataforma que fomenta
          el diálogo abierto y promueve conexiones basadas en algo más que solo
          calificaciones por escrito.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">Política de Cancelación</h2>
        <p className="mb-4">
          Puede cancelar su cuenta de usuario gratuita o de pago en cualquier
          momento. Después de cancelar la cuenta, siempre puede reactivarla
          usando su nombre de usuario y contraseña existentes.
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">Política de Reembolsos</h2>
        <p className="mb-4">
          Boca En Boca no ofrece reembolsos para suscripciones de pago. Todas
          las suscripciones se facturan anualmente por adelantado. Puede cambiar
          de plan de suscripción en cualquier momento, incluyendo cambiar a una
          cuenta gratuita, pero cualquier cambio se hará efectivo al final de su
          período de suscripción actual.
        </p>

        <p className="mb-4">
          Si tiene preguntas sobre la Política de Cancelación y Reembolsos, por
          favor contáctenos a
          <a
            href="mailto:support@bocaenboca.com"
            className="text-orange-500 cursor-pointer ml-1"
            target="_blank"
            rel="noopener noreferrer"
          >
            support@bocaenboca.com
          </a>
        </p>

        <h2 className="text-xl font-bold mt-6 mb-4">Legal</h2>
        <p className="mb-4">
          Boca En Boca™ es una marca registrada propiedad de The Peddler Group,
          Inc., una corporación formada en Texas, establecida el 18 de diciembre
          de 2018.
        </p>
      </div>
    </div>
  );
};

export default RefundSp;
